/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./custom-pagination.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../node_modules/primeng/dropdown/primeng-dropdown.ngfactory";
import * as i4 from "primeng/dropdown";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/common";
import * as i7 from "../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i8 from "@ng-bootstrap/ng-bootstrap";
import * as i9 from "./custom-pagination.component";
var styles_CustomPaginationComponent = [i0.styles];
var RenderType_CustomPaginationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomPaginationComponent, data: {} });
export { RenderType_CustomPaginationComponent as RenderType_CustomPaginationComponent };
function View_CustomPaginationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "col-auto page-size"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 6, "p-dropdown", [["class", "dropdown-pagination"]], [[2, "ui-inputwrapper-filled", null], [2, "ui-inputwrapper-focus", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "onChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.itemsPerPage = $event) !== false);
        ad = (pd_0 && ad);
    } if (("onChange" === en)) {
        var pd_1 = (_co.optionChange.emit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_Dropdown_0, i3.RenderType_Dropdown)), i1.ɵdid(4, 13877248, null, 1, i4.Dropdown, [i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef, i1.NgZone], { autoDisplayFirst: [0, "autoDisplayFirst"], options: [1, "options"] }, { onChange: "onChange" }), i1.ɵqud(603979776, 1, { templates: 1 }), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.Dropdown]), i1.ɵdid(7, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(9, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i1.ɵted(10, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_10 = false; var currVal_11 = _co.itemsPerPageOptions; _ck(_v, 4, 0, currVal_10, currVal_11); var currVal_12 = _co.itemsPerPage; _ck(_v, 7, 0, currVal_12); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("PAGINATION.SHOW")); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 4).filled; var currVal_2 = i1.ɵnov(_v, 4).focused; var currVal_3 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 9).ngClassValid; var currVal_8 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_13 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("PAGINATION.ITEMS-PAGES")); _ck(_v, 10, 0, currVal_13); }); }
function View_CustomPaginationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "col-auto v-divider"]], null, null, null, null, null))], null, null); }
function View_CustomPaginationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "col-auto"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_CustomPaginationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "row align-items-center"], ["ngClass", "{'m-t-10': !rightSide}"], ["style", "justify-content: flex-end;"]], null, null, null, null, null)), i1.ɵprd(512, null, i6.ɵNgClassImpl, i6.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i6.NgClass, [i6.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomPaginationComponent_1)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomPaginationComponent_2)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 8, "div", [["class", "dv-pagination"], ["style", "flex-grow: 1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 7, "ngb-pagination", [["aria-label", "Pagination"], ["role", "navigation"]], null, [[null, "pageChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChange" === en)) {
        var pd_0 = ((_co.currentPage = $event) !== false);
        ad = (pd_0 && ad);
    } if (("pageChange" === en)) {
        var pd_1 = (_co.pageChange.emit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_NgbPagination_0, i7.RenderType_NgbPagination)), i1.ɵdid(9, 573440, null, 6, i8.NgbPagination, [i8.NgbPaginationConfig], { collectionSize: [0, "collectionSize"], maxSize: [1, "maxSize"], page: [2, "page"], pageSize: [3, "pageSize"] }, { pageChange: "pageChange" }), i1.ɵqud(603979776, 2, { tplEllipsis: 0 }), i1.ɵqud(603979776, 3, { tplFirst: 0 }), i1.ɵqud(603979776, 4, { tplLast: 0 }), i1.ɵqud(603979776, 5, { tplNext: 0 }), i1.ɵqud(603979776, 6, { tplNumber: 0 }), i1.ɵqud(603979776, 7, { tplPrevious: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomPaginationComponent_3)), i1.ɵdid(17, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row align-items-center"; var currVal_1 = "{'m-t-10': !rightSide}"; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = !_co.hideItemsPerPage; _ck(_v, 4, 0, currVal_2); var currVal_3 = !_co.hideItemsPerPage; _ck(_v, 6, 0, currVal_3); var currVal_4 = _co.totalItems; var currVal_5 = _co.maxSize; var currVal_6 = _co.currentPage; var currVal_7 = _co.itemsPerPage; _ck(_v, 9, 0, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = !_co.rightSide; _ck(_v, 17, 0, currVal_8); }, null); }
export function View_CustomPaginationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-custom-pagination", [], null, null, null, View_CustomPaginationComponent_0, RenderType_CustomPaginationComponent)), i1.ɵdid(1, 114688, null, 0, i9.CustomPaginationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomPaginationComponentNgFactory = i1.ɵccf("app-custom-pagination", i9.CustomPaginationComponent, View_CustomPaginationComponent_Host_0, { totalItems: "totalItems", maxSize: "maxSize", hideItemsPerPage: "hideItemsPerPage", rightSide: "rightSide", currentPage: "currentPage", itemsPerPage: "itemsPerPage" }, { currentPageChange: "currentPageChange", itemsPerPageChange: "itemsPerPageChange", pageChange: "pageChange", optionChange: "optionChange" }, ["*"]);
export { CustomPaginationComponentNgFactory as CustomPaginationComponentNgFactory };
