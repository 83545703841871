import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  constructor(private authService: AuthenticationService,
              private translate: TranslateService) {this.language = translate.currentLang; }
  language = '';
  username = '';

  isNavbarCollapsed = false;

  ngOnInit() {
    let username = localStorage.getItem('username');
    this.username = username;
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  canUserSignDoc() {
    return this.authService.isAccountant();
  }
  changeLanguage(lang: string){
    if(lang === 'en'){
      this.translate.use('en');
      localStorage.setItem('lang','en');
    }else if(lang === 'th'){
      this.translate.use('th');
      localStorage.setItem('lang','th');
    }
  }

  isAdmin() {
    return this.authService.isAdmin();
  }

  isUIH() {
    return this.authService.isUIH();
  }

  isAccountant() {
    return this.authService.isAccountant();
  }

  isAccountantAdmin() {
    return this.authService.isAccountantAdmin();
  }

  isIT() {
    return this.authService.isIT();
  }

  isCustomer() {
    return this.authService.isCustomer();
  }

  getUsername() {
    return localStorage.getItem('username');
  }

  getRole() {
    return localStorage.getItem('group');
  }
}
