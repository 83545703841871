/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./filter-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./filter-header.component";
var styles_FilterHeaderComponent = [i0.styles];
var RenderType_FilterHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FilterHeaderComponent, data: {} });
export { RenderType_FilterHeaderComponent as RenderType_FilterHeaderComponent };
function View_FilterHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-spinner fa-spin m-r-5"]], null, null, null, null, null))], null, null); }
function View_FilterHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fa fa-search m-r-5"]], null, null, null, null, null))], null, null); }
export function View_FilterHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "row align-items-center mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 12, "div", [["class", "col-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "button", [["class", "btn btn-dv btn-clean btn-wide mr-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clear.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["class", "fa fa-times m-r-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 6, "button", [["class", "btn btn-dv"], ["style", "width: 100px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.search.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterHeaderComponent_1)), i1.ɵdid(13, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterHeaderComponent_2)), i1.ɵdid(15, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(16, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.loading === true); _ck(_v, 13, 0, currVal_2); var currVal_3 = (_co.loading === false); _ck(_v, 15, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("FILTER.TITLE")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("FILTER.CLEAR")); _ck(_v, 9, 0, currVal_1); var currVal_4 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("FILTER.TITLE")); _ck(_v, 16, 0, currVal_4); }); }
export function View_FilterHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-filter-header", [], null, null, null, View_FilterHeaderComponent_0, RenderType_FilterHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i4.FilterHeaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FilterHeaderComponentNgFactory = i1.ɵccf("app-filter-header", i4.FilterHeaderComponent, View_FilterHeaderComponent_Host_0, { loading: "loading" }, { search: "search", clear: "clear" }, []);
export { FilterHeaderComponentNgFactory as FilterHeaderComponentNgFactory };
