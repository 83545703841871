<!--<div class="container-fluid login-container login-bg">-->
<!--  <img src="/assets/images/polygon_crop.png" class="img-fluid">-->
  <div class="row align-items-center login-container-row">
    <div class="col-md-6 title-block">
      One Stop Service for
      <div class="e-tax-title mt-2">E-TAX</div>
      <div class="e-tax-subtitle">Invoice & Receipt</div>
    </div>
    <div class="col-md-6">
      <div class="login-form">
        <div class="welcome-text mt-sm-4">
          {{ msg }}
          <i [hidden]="!isLoading" class="fas fa-spinner fa-spin"></i>
        </div>
        <div [hidden]="!showRequestOTP" class="form-group">
          <a [routerLink]="['/login']" class="btn btn-dv btn-block">Request OTP</a>
        </div>
<!--        <div class="float-right">-->
<!--          <div class="footer-text">Powered by</div>-->
<!--          <img src="../../assets/images/logo.png" width="100">-->
<!--        </div>-->
      </div>
    </div>
  </div>
