import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {PeriodReportComponent} from './period-report.component';


export const periodReportRoutes: Routes = [
  {
    path: 'period-report',
    component: PeriodReportComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(periodReportRoutes)],
  exports: [RouterModule]
})
export class PeriodReportRoutingModule {
}
