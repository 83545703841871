import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from "../services/authentication.service";
import {ActivatedRoute, Router} from "@angular/router";
import * as _ from 'lodash';

@Component({
  selector: 'app-validate-otp',
  templateUrl: './validate-otp.component.html',
  styleUrls: ['./validate-otp.component.scss']
})
export class ValidateOtpComponent implements OnInit {
  msg: string = 'Validating OTP';
  otp: string = null;
  isLoading: boolean = true;
  showRequestOTP: boolean = false;

  constructor(
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.otp = params['password'];
      console.log(params);
      if(_.isNull(this.otp) || _.isEmpty(this.otp)) {
        console.log(this.otp);
        this.isLoading = false;
        this.msg = 'OTP is empty, failed.';
        return;
      }
      this.validateOTP(this.otp);
    });
  }

  validateOTP(otp: string) {
    this.authService.validateOTP(otp).subscribe(
      success => {
        let token = _.get(success, 'token');
        localStorage.clear();
        localStorage.setItem('currentUser', token);
        this.authService.updateUserInfo(success);
        if (token != null) {
          this.router.navigate([''])
        }
      },
      failed => {
        this.isLoading = false;
        this.showRequestOTP = true;
        this.msg = failed.error['detail'];
      }
    )
  }
}
