import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';
  defaultLang = 'en';

  constructor(private translateService: TranslateService){
    if (localStorage.getItem('lang')) {
      this.defaultLang = localStorage.getItem('lang');
    }
    translateService.setDefaultLang('en');
    translateService.use(this.defaultLang);
  }
}
