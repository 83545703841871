import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DashboardModule} from './dashboard/dashboard.module';
import {SearchCustomerModule} from './search-customer/search-customer.module';
import {SearchModule} from './search/search.module';
import {ImportDataModule} from './import-data/import-data.module';
import {ProfileModule} from './profile/profile.module';
import {DailyReportModule} from './daily-report/daily-report.module';
import {PeriodReportModule} from './period-report/period-report.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DashboardModule,
    SearchCustomerModule,
    DashboardModule,
    SearchModule,
    ImportDataModule,
    ProfileModule,
    DailyReportModule,
    PeriodReportModule
  ]
})
export class FeaturesModule { }
