import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '../services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from "../services/api.service";
import {TranslateService} from "@ngx-translate/core";
import * as _ from 'lodash';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  password1: string;
  password2: string;
  strength = 0;
  token: string;
  isLoading = false;
  errorMessage: string = null;

  constructor(private apiService: ApiService,
              private router: Router,
              private translate: TranslateService,
              private tokenService: AuthenticationService,
              private activatedRoute: ActivatedRoute,) {
  }
    ngOnInit() {
    this.checkToken();
  }
  // strengthChange(strength: number): void {
  //   this.strength = strength;
  // }
  checkToken() {
    this.activatedRoute.params.subscribe(
      params => {
        this.token = params['token'];
        if (params.id) {
          this.tokenService.validateResetPasswordToken(
            {token: this.token}).subscribe(data => {
            },
            err => {
            });
        }
      }
    );
  }
  gotoLogin() {
    if (!this.validatePassword()) {
      return null;
    }
    this.router.navigate(['/login']);
  }
  changePassword() {
    this.errorMessage = null;
    if (_.isNil(this.password1) || _.isNil(this.password2)) {
      this.errorMessage = 'โปรดกรอกข้อมูลให้ครบถ้วน';
      return;
    }
    if (this.password1 !== this.password2) {
      this.errorMessage = 'รหัสผ่านไม่ตรงกัน';
      return;
    }
    this.isLoading = true;
    this.tokenService
      .confirmPassword({
        password: this.password1,
        token: this.token,
      })
      .subscribe(
        (user: any) => {
          this.errorMessage = 'Change password success';
          this.gotoLogin()
        },
        error => {
          this.isLoading = false;
          if (error.status === 400) {
            this.errorMessage = 'รหัสผ่านต้องมีอย่างน้อง 8 ตัวและต้องมีตัวอักษรอยู่ด้วย';
          } else if(error.status === 404) {
            this.errorMessage = 'ลิงค์หมดอายุ';
          }else {
            this.errorMessage = 'รหัสผ่านง่ายเกินไป';
          }
        }
      );
  }
  validatePassword() {
    return this.password1 === this.password2;
  }
}
