import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {ApiService} from '../../../services/api.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-profile-password',
  templateUrl: './profile-password.component.html',
  styleUrls: ['./profile-password.component.scss']
})
export class ProfilePasswordComponent {
  oldPassword = '';
  newPassword = '';
  confirmPassword = '';
  errorMessage: string = null;
  errorMsgForShow: String = null;

  constructor(private apiService: ApiService, private router: Router,
              private translate: TranslateService) {
  }

  submit() {
    this.errorMessage = null;
    if (this.translate.currentLang !== 'en') {
      if (this.newPassword.length === 0) {
        this.errorMessage = 'โปรดกรอกรหัสผ่าน';
      } else if (this.confirmPassword !== this.newPassword) {
        this.errorMessage = 'รหัสผ่านไม่ตรงกัน';
      } else {
        this.apiService.updatePassword({
          old_password: this.oldPassword,
          new_password: this.newPassword
        }).subscribe(
          () => this.router.navigate(['']),
          error => {
            this.checkErrorMsg(error);
          }
        );
      }
    } else {
      if (this.newPassword.length === 0) {
        this.errorMessage = 'Please fill Password';
      } else if (this.confirmPassword !== this.newPassword) {
        this.errorMessage = 'Passwords do not match.';
      } else {
        this.apiService.updatePassword({
          old_password: this.oldPassword,
          new_password: this.newPassword
        }).subscribe(
          () => this.router.navigate(['']),
          error => {
            this.checkErrorMsg(error);
          }
        );
      }
    }

  }

  checkErrorMsg(error) {
    this.errorMsgForShow = this.apiService.getErrorMessage(error);
    if (this.translate.currentLang === 'en') {
      this.errorMessage = this.errorMsgForShow[0];
    } else {
      this.errorMessage = this.errorMsgForShow[1];
    }
  }
}
