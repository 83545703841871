export class PeriodReport {
  static FILTER_COMPANY_CODE = [
    {label: 'Siam Furukawa Co.,Ltd.', value: '1000'},
    {label: 'Siam Furukawa Trading Co.,Ltd.', value: '2000'}
  ];

  static FILTER_COMPANY_CODE_SHORT = [
    {label: 'SFC', value: '1000'},
    {label: 'SFT', value: '2000'}
  ];

  companyCode: string;
  documentCount: number;
  selected: boolean;

  constructor(jsonObject: any) {
    this.companyCode = jsonObject.company;
    this.documentCount = jsonObject.document_count;
    this.selected = false;
  }

  getLabelCompanyCode(): string {
    const obj = PeriodReport.FILTER_COMPANY_CODE.find(item => item.value === this.companyCode);
    return obj.label;
  }

  getLabelCompanyCodeShort(): string {
    const obj = PeriodReport.FILTER_COMPANY_CODE_SHORT.find(item => item.value === this.companyCode);
    return obj.label;
  }
}
