<div class="header">
  <div class="row align-items-center">
    <div class="col-md">
      <h3>{{'MENU.MONITORING' | translate}}</h3>
    </div>
    <div class="col-md-2">
      <div class="dropdown" #dd="ngbDropdown" ngbDropdown>
        <!--<div class="form-label-group">-->
          <input #ddi class="form-control" type="text" id="calendarDropdown" [value]="dateShowing"
                 ngbDropdownToggle placeholder="วันที่" readonly>
          <!--<label for="calendarDropdown">วันที่</label>-->
          <div class="calendar-trigger" (click)="$event.stopPropagation(); dd.toggle()">
            <span class="far fa-calendar"></span>
          </div>
        <!--</div>-->
        <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="calendarDropdown">
          <div (click)="$event.stopPropagation();">
            <p-calendar [(ngModel)]="selectedDate" dateFormat="dd/mm/yy" readonlyInput="true"
                        [inline]="true" (onSelect)="loadDailyTransactionCount()"></p-calendar>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="box-parent scrollable" style="height: calc(100vh - 2.25rem - 150px);">
  <canvas id="signing-counter-line-chart">{{ charts_signing_counter_line }}</canvas>
</div>
