import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { Chart } from 'chart.js';
import * as moment from 'moment';
import {SelectItem} from 'primeng/api';
import * as _ from 'lodash';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {CALENDAR_LOCALE} from '../../../services/constant';

@Component({
  selector: 'app-dashboard-for-it',
  templateUrl: './dashboard-for-it.component.html',
  styleUrls: ['./dashboard-for-it.component.scss']
})
export class DashboardForItComponent implements OnInit {

  // month amount to show in graph
  monthAmount = 6;
  // activity amount to show in activity list
  activityAmount = 2;
  monthList = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'];
  monthListDropdown = [
    {label: 'มกราคม', value: 1},
    {label: 'กุมภาพันธ์', value: 2},
    {label: 'มีนาคม', value: 3},
    {label: 'เมษายน', value: 4},
    {label: 'พฤษภาคม', value: 5},
    {label: 'มิถุนายน', value: 6},
    {label: 'กรกฎาคม', value: 7},
    {label: 'สิงหาคม', value: 8},
    {label: 'กันยายน', value: 9},
    {label: 'ตุลาคม', value: 10},
    {label: 'พฤศจิกายน', value: 11},
    {label: 'ธันวาคม', value: 12}
  ];
  yearList: any[] = [];
  selectedCompany: SelectItem[];
  company: any;
  selectedMonth: any;
  selectedYear: any;
  activityList: any;
  selectedDate: Date = new Date();

  charts_bar_stacked: any = null;
  charts_signing_counter_bar_stacked: any = null;
  charts_signing_counter_line: any = null;
  chartLabels = [];
  monthly_loading = false;
  daily_loading = false;
  bar_stacked_title = "";
  charts_signing_counter_bar_stacked_title = "";
  charts_transaction_count_title = "";
  calendarLocale;

  constructor(private apiService: ApiService,
              private translate: TranslateService) {
  }

  ngOnInit() {
     this.lang();
    this.populateDropdown();
    this.loadDailyTransactionCount();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.ngOnInit();
    });
  }
    lang() {
    if (this.translate.currentLang === 'en') {
      this.calendarLocale = CALENDAR_LOCALE.EN;
    } else {
      this.calendarLocale = CALENDAR_LOCALE.TH;
    }
  }

  get dateShowing() {
    return moment(this.selectedDate).format('DD/MM/YYYY');
  }

  populateDropdown() {
    this.company = [{
      label: this.translate.instant('COMMON.ALL'),
      value: null
    }];

    this.apiService.getCompanyGroup().subscribe(
      (result) => {
        _.forEach(result, (obj) => {
          this.company.push({
            label: obj.name + ' (' + obj.short_name + ')',
            value: obj.id
          })
        });
      }
    );

    var currentDate = new Date();
    // populate year list back within 10 years
    var thisYear = currentDate.getFullYear();
    for (let i = 0; i <= 10; i++) {
      this.yearList.push({label: (thisYear - i).toString(), value: thisYear - i});
    }

    // set current month and year
    this.selectedMonth = currentDate.getMonth() + 1;
    this.selectedYear = thisYear;
  }

  loadGraph() {
    var currentDate = new Date();
    var year = currentDate.getFullYear();
    var month = currentDate.getMonth() - this.monthAmount + 2;

    while (month <= 0) {
      year--;
      month += 12;
    }

    // populate chart label by month and year
    for (let i = 0; i < this.monthAmount; i++, month++) {
      if (month > 12) {
        month = 1;
        year++;
      }
      this.chartLabels.push(month + '/' + year);
    }

    // get graph data from API
    const data = {
      month_amount: this.monthAmount
    }
    this.apiService.getDashboardLengthData(data).subscribe(
      (obj: any) => {

      }
    )
  }

  // load data from current month
  loadMonthlyData() {
    let data = {
      year: this.selectedYear
    };

    if (this.selectedCompany != null)
      data['group_id'] = this.selectedCompany;

    this.monthly_loading = true;
    this.apiService.getDashboardMonthlyData(data).subscribe(
      (obj: any) => {
        this.monthly_loading = false;

        this.create_signing_counter_bar_stacked(obj.signing_counter);

        Chart.plugins.register({
          beforeDraw: function(chart) {
            if (chart.config.options.elements.center) {
              chart.clear();
              //Get ctx from string
              var ctx = chart.chart.ctx;

              //Get options from the center object in options
              var centerConfig = chart.config.options.elements.center;
              var fontStyle = centerConfig.fontStyle || 'Arial';
              var txt = centerConfig.text;
              var color = centerConfig.color || '#000';
              var sidePadding = centerConfig.sidePadding || 20;
              var sidePaddingCalculated = (sidePadding/100) * (chart.innerRadius * 2)
              //Start with a base font of 30px
              ctx.font = "30px " + fontStyle;
              var stringWidth = ctx.measureText(txt).width;
              var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

              // Find out how much the font can grow in width.
              var widthRatio = elementWidth / stringWidth;
              var newFontSize = Math.floor(30 * widthRatio);
              var elementHeight = (chart.innerRadius * 2);

              // Pick a new font size so it will not be larger than the height of label.
              var fontSizeToUse = Math.min(newFontSize, elementHeight);

              //Set font settings to draw it correctly.
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
              var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 1.2);
              ctx.font = fontSizeToUse+"px " + fontStyle;
              ctx.fillStyle = color;

              //Draw text in center
              ctx.fillText(txt, centerX, centerY);
            }
          },

          afterDraw: function(chart) {
            if (chart.data.datasets.length === 0) {
              // No data is present
              var ctx = chart.chart.ctx;
              var width = chart.chart.width;
              var height = chart.chart.height
              chart.clear();

              ctx.save();
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              ctx.font = "14pt Tahoma";
              ctx.fillStyle = '#000';
              ctx.fillText('ไม่ข้อมูลแสดง', width / 2, height / 2);
              ctx.restore();
            }
          }
        });
      }
    )
  }

  create_signing_counter_bar_stacked(data){
    let tax_invoice = data.tax_invoice;
    let receipt = data.receipt;
    let credit_note = data.credit_note;
    let debit_note = data.debit_note;
    let pdf = data.pdf;
    let color = Chart.helpers.color;

    this.charts_signing_counter_bar_stacked_title = 'จำนวนครั้งการ Sign รายเดือน';
    var barChartData = {
      labels: this.monthList,
      datasets: [{
        label: 'Tax Invoice',
        backgroundColor: color("rgb(54, 162, 235)").alpha(0.5).rgbString(),
        borderColor: "rgb(54, 162, 235)",
        data: tax_invoice
      }, {
        label: 'Receipt',
        backgroundColor: color("rgb(75, 192, 192)").alpha(0.5).rgbString(),
        borderColor: "rgb(75, 192, 192)",
        data: receipt
      }, {
        label: 'Credit Note',
        backgroundColor: color("rgb(255, 159, 64)").alpha(0.5).rgbString(),
        borderColor: "rgb(255, 159, 64)",
        data: credit_note
      }, {
        label: 'Debit Note',
        backgroundColor: color("rgb(255, 99, 132)").alpha(0.5).rgbString(),
        borderColor: "rgb(255, 99, 132)",
        data: debit_note
      }, {
        label: 'PDF',
        backgroundColor: color("rgb(255, 0, 132)").alpha(0.5).rgbString(),
        borderColor: "rgb(255, 0, 132)",
        data: pdf
      }]
    };

    if (this.charts_signing_counter_bar_stacked == null) {
      this.charts_signing_counter_bar_stacked = new Chart('signing-counter-bar-chart', {
        type: 'bar',
        data: barChartData,
        options: {
          tooltips: {
            titleFontSize: 17,
            bodyFontSize: 17,
            mode: 'index',
            intersect: false,
            callbacks: this.getformatLabelFunction('ครั้ง', true)
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true,
            }],
            yAxes: [{
              stacked: true,
              ticks: {
                beginAtZero: true,
                callback: function(value) { if (value % 1 === 0) {return value;} }
              }
            }]
          },
          legend: {
            position: 'top',
          }
        }
      });
     }
     else {
       this.charts_signing_counter_bar_stacked.data = barChartData;
       this.charts_signing_counter_bar_stacked.update();
     }
  }

  create_signing_counter_line_chart(data){
    let label = data.label;
    let fill_data = data.data;
    let color = Chart.helpers.color;

    let lineChartData = {
      labels: label,
      datasets: [{
          label: "Transaction Count",
          data: fill_data,
          backgroundColor: color("rgb(255, 99, 132)").alpha(0.5).rgbString(),
          borderColor: "rgb(255, 99, 132)",
      }]
    };

    this.charts_transaction_count_title = 'จำนวน Transaction ที่เข้าสู่ระบบรายนาที';
    if (this.charts_signing_counter_line == null) {
      this.charts_signing_counter_line = new Chart('signing-counter-line-chart', {
        type: 'line',
        data: lineChartData,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          hover: {
            mode: 'nearest',
            intersect: true
          },
          scales: {
            xAxes:[{
              type: 'time',
              time: {
                format: "HH:mm",
                unit: 'hour',
                unitStepSize: 1,
                displayFormats: {
                  'minute': 'HH:mm',
                  'hour': 'HH:mm',
                  min: '00:00',
                  max: '23:59'
                },
              }
            }],
            yAxes: [{
              ticks: {
                beginAtZero: true,
                callback: function(value) {if (value % 1 === 0) {return value;}}
              }
            }]
          },
        }
      });
     }
     else {
       this.charts_signing_counter_line.data = lineChartData;
       this.charts_signing_counter_line.update();
     }
  }


  loadDailyTransactionCount() {
    const data = {
      date: moment(this.selectedDate).format('YYYY-MM-DD')
    };

    this.daily_loading = true;
    this.apiService.getDashboardSignCounterMinute(data).subscribe(
      (obj: any) => {
        this.daily_loading = false;
        this.create_signing_counter_line_chart(obj.signing_counter_minute);
      },
      (error) => {
      }
    );
  }

  formatCurrency(currency) {
    return parseFloat(currency).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  getformatLabelFunction(unitName, showSum) {
    let tooltip = {
      label: function (tooltipItem, data) {
        let dataLabel = data.datasets[tooltipItem.datasetIndex].label;
        if (dataLabel == null)
          dataLabel = data.labels[tooltipItem.index];

        let value =  data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        let valueStr = ' : ' + value.toLocaleString();
        dataLabel += valueStr + ' ' + unitName;

        if (showSum)
          window['total'] += value;

        return dataLabel;
      }
    };

    if (showSum) {
      tooltip['afterTitle'] = function() {
        window['total'] = 0
      };

      tooltip['footer'] = function() {
        return ["รวมทั้งหมด : " + window['total'].toLocaleString() + ' ' + unitName];
      }
    }

    return tooltip
  }
}

