import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from '../login/login.component';
import {MainComponent} from './main.component';
import {AuthGuard} from '../auth-guard.service';
import {dashboardRoutes} from '../features/dashboard/dashboard-routing.module';
import {searchCustomerRoutes} from '../features/search-customer/search-customer-routing.module';
import {ValidateOtpComponent} from '../validate-otp/validate-otp.component';
import {searchRoutes} from '../features/search/search-routing.module';
import {importDataRoutes} from '../features/import-data/import-data-routing.module';
import {profileRoutes} from '../features/profile/profile-routing.module';
import {passwordResetRouters} from '../password-reset/password-reset-routing.module';
import {dailyReportRoutes} from '../features/daily-report/daily-report-routing.module';
import {periodReportRoutes} from '../features/period-report/period-report-routing.module';


export const mainRouter: Routes = [
  {path: 'validate-otp', component: ValidateOtpComponent},
  {path: 'validate-otp/:msg', component: ValidateOtpComponent},
  {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  {path: 'login/:msg', component: LoginComponent},
  {path: 'login', component: LoginComponent},
  {
    path: '',
    component: MainComponent,
    children: [
      ...dashboardRoutes,
      ...searchCustomerRoutes,
      ...searchRoutes,
      ...importDataRoutes,
      ...profileRoutes,
      ...dailyReportRoutes,
      ...periodReportRoutes
    ],
    canActivate: [AuthGuard]
  },
  ...passwordResetRouters
];

@NgModule({
  imports: [RouterModule.forRoot(mainRouter)],
  exports: [RouterModule]
})
export class MainRoutingModule { }
