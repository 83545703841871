import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfilePasswordComponent } from './profile-password/profile-password.component';
import {ProfileListComponent} from './profile-list/profile-list.component';

export const profileRoutes: Routes = [
  {
    path: 'profile',
    children: [
      {
        path: 'password',
        component: ProfilePasswordComponent
      },
      {
        path: 'list',
        component: ProfileListComponent
      }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(profileRoutes)],
  exports: [RouterModule]
})
export class ProfileRoutingModule { }
