import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../services/api.service';
import {AuthenticationService} from '../../services/authentication.service';
import {NgbModal, NgbModalRef, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TemplateService} from '../../services/template.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {CALENDAR_LOCALE} from '../../services/constant';
import {HttpParams} from '@angular/common/http';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-import-data',
  templateUrl: './import-data.component.html',
  styleUrls: ['./import-data.component.scss']
})
export class ImportDataComponent implements OnInit {
  itemsPerPage = 40;
  startDate: Date = null;
  endDate: Date = null;
  minDate: Date;
  maxDate: Date;
  totalItems = 0;
  selectedValues = [];
  currentPage = 1;
  statusData: any;
  selectedStatus: any;
  calendarLocale = CALENDAR_LOCALE.TH;
  tableCol = [];
  loading = false;
  sortBy: string;
  sortMode: string;
  tableData: any;
  modalData: any;
  disable = false;
  id_error: any;
  modal: NgbModalRef;
  loading_main = false;
  checkdate = null;


  constructor(
    private apiService: ApiService,
    private authService: AuthenticationService,
    private modelService: NgbModule,
    private translate: TranslateService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.loadTexts();
    });
    this.loadTexts();
  }
  loadTexts() {
       if (this.translate.currentLang === 'en') {
      this.calendarLocale = CALENDAR_LOCALE.EN;
    } else {
      this.calendarLocale = CALENDAR_LOCALE.TH;
       }
       this.statusData = [
         {label: this.translate.instant('COMMON.ALL'), value: null},
         {label: this.translate.instant('TEXT.SUCCESS'), value: 'success'},
         {label: this.translate.instant('TEXT.FAILED'), value: 'fail'}
       ];
       this.translate.get('COMMON.ALL').subscribe((res: string) => {
      this.tableCol = _.uniqBy(this.tableCol, 'field');
    });
       this.startDate = new Date();
       this.startDate.setDate(this.startDate.getDate() - 60);
       this.endDate = new Date();
  }

  resolveError($event, popup: any, data: any) {
     this.modalData = data.remark;
     this.disable = false;
     this.modal = this.modalService.open(popup, {backdrop: 'static', keyboard: false});
    // this.id_error = rowData.id;

  }
  revertBack() {
    this.modal.close();
  }
  get yearRange() {
    const thisYear = (new Date()).getFullYear();
    return (thisYear - 40) + ':' + thisYear;
  }

  get dateRange() {
    if (!this.startDate && !this.endDate) {
      return null;
    }

    const start = this.startDate ? moment(this.startDate).format('DD/MM/YYYY') : '';
    const end = this.endDate ? moment(this.endDate).format('DD/MM/YYYY') : '';
    return start + ' - ' + end;
  }
  pageChanged() {
     this.loadData();
  }

  setMinDate() {
    this.minDate = this.startDate;
  }

  setMaxDate() {
    this.maxDate = this.endDate;
  }
    stopPropagation(event) {
    event.stopPropagation();
  }
  sentdate() {
    this.checkdate = 1 ;
  }

    sort(event) {
    this.sortBy = event.field;
    if (event.order === 1) {
      this.sortMode = 'asc';
    } else {
      this.sortMode = 'desc';
    }
  }
   clearSearch() {
    this.startDate = new Date();
    this.startDate.setDate(this.startDate.getDate() - 60);
    this.selectedStatus = null;
    this.startDate = null;
    this.endDate = new Date();
    this.startDate = new Date();
    this.sortBy = null;
    this.sortMode = null;
    this.totalItems  = null;
    this.disable = false;
    this.modal = null;
    this.checkdate = null;
    this.loadTexts();
    this.loadData();
  }

  searchButtonClicked() {
    this.currentPage = 1;
    this.sortBy = null;
    this.loadData();
  }

  loadData() {
    this.tableData = [];
    this.loading = true;
    const startDateToStr = this.startDate === null ? this.startDate : moment(this.startDate).format('YYYY-MM-DD');
    const endDateToStr = this.endDate === null ? this.endDate : moment(this.endDate).format('YYYY-MM-DD');
    let params = new HttpParams()
      .set('date_start', _.toString(startDateToStr))
      .set('date_end', _.toString(endDateToStr))
      .set('page_size', _.toString(this.itemsPerPage))
    params = params.append('page', this.currentPage.toString());
    if (this.selectedStatus) {
      params = params.append('result', this.selectedStatus); }
    this.apiService.getErrors(params).subscribe(
      (data) => {
      this.totalItems = data.count;
      this.tableData = data.results;
      this.loading = false;
    }
      );
  }

}
