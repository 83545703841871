import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import * as _ from 'lodash';

@Injectable()
export class HttpClientService {

  constructor(
    private http: HttpClient,
    private router: Router) { }

    checkError(apiFunction: Observable<any>): any {
    return new Observable((observer) => {
      apiFunction.subscribe((res) => {
        observer.next(res);
        observer.complete();
      }, (err) => {
        if (
          _.get (err, 'error.detail') === 'Signature has expired.' ||
          _.get (err, 'error.detail') === 'Error decoding signature.' ||
          _.get (err, 'error.detail') === 'Invalid signature.' ||
          _.get (err, 'error.detail') === 'User account is disabled.' ||
          _.get (err, 'error.detail') === 'บัญชีผู้ใช้ถูกระงับ'
        ) {
          localStorage.clear();
          this.router.navigate(['login', 'ล็อกอินหมดอายุ โปรดล็อกอินอีกครั้ง'], {skipLocationChange: true});
        }
        observer.error(err);
      });
    });
  }
  private getHeader() {
    return new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('currentUser')).set('Accept-Language', 'th,en');
  }

  get(url, params?) {
    return this.checkError(this.http.get(url, {params, headers: this.getHeader()}));
  }

  getBlob(url, params?) {
    return this.checkError(this.http.get(url, {params, headers: this.getHeader(), responseType: 'blob'}));
  }

  post(url, data) {
    return this.checkError(this.http.post(url, data, {headers: this.getHeader()}));
  }

  postBlob(url, data) {
    return this.checkError(this.http.post(url, data, {headers: this.getHeader(), responseType: 'blob'}));
  }

  put(url, data, params?) {
    return this.checkError(this.http.put(url, data, {params, headers: this.getHeader()}));
  }

  patch(url, data) {
    return this.checkError(this.http.patch(url, data, {headers: this.getHeader()}));
  }

  delete(url) {
    return this.checkError(this.http.delete(url, {headers: this.getHeader()}));
  }
}
