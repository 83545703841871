import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SearchCustomerComponent} from './search-customer.component';

export const searchCustomerRoutes: Routes = [
  {
    path: 'search-customer',
    component: SearchCustomerComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(searchCustomerRoutes)],
  exports: [RouterModule]
})
export class SearchCustomerRoutingModule { }
