import {Component, OnInit, ViewChild, TemplateRef} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import * as _ from 'lodash';
import {ProfileListComponent} from '../profile-list/profile-list.component';
import {MODAL_CONFIG} from '../../../services/constant';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'profile-list-detail',
  templateUrl: './profile-list-detail.component.html',
  styleUrls: ['./profile-list-detail.component.scss']
})
export class ProfileListDetailComponent implements OnInit {
  modalRef: BsModalRef;
  user: any = {};
  groups: any = [];
  title = '';
  createMode = false;
  errorMessage: string = null;
  errorMsgForShow: string = null;
  errorMsgForShows: string = null;
  passwordPlaceHolder = '';
  parent: ProfileListComponent = null;
  checkUser = false;
  statusChoice = [
    {label: 'Active', value: true},
    {label: 'Inactive', value: false},
  ];

  // @ts-ignore
  @ViewChild('modalTemplate') modalTemplate: TemplateRef<any>;

  constructor(private apiService: ApiService,
              private modalService: BsModalService,
              private translate: TranslateService) {
  }

  open(parent: ProfileListComponent, selectedUser) {
    this.parent = parent;
    this.errorMessage = null;

    if (selectedUser != null) {
      this.user = _.cloneDeep(selectedUser);
      // this.user.group = this.user.group.id;
      delete this.user.status;
      this.createMode = false;
      this.checkUser = true;
      this.title = this.translate.instant('PROFILE.LIST.MODAL.TITLE') + ' : ' + this.user.username;
      this.passwordPlaceHolder = '(' + this.translate.instant('PROFILE.LIST.MODAL.PASSWORD-PLACEHOLDER') + ')';
    } else {
      // this.user = {"group": this.groups[0]['value']};
      this.createMode = true;
      this.checkUser = false;
      this.title = this.translate.instant('PROFILE.LIST.MODAL.ADD-USER');
      this.passwordPlaceHolder = this.translate.instant('PROFILE.LIST.MODAL.PASSWORD');
    }

    this.modalRef = this.modalService.show(this.modalTemplate, MODAL_CONFIG);
  }

  ngOnInit() {
    this.loadGroups();
  }


  submit() {
    if (this.translate.currentLang !== 'en') {
      const errorField = [];
      this.errorMessage = null;

      if (this.user.username === null || this.user.username.length === 0) {
        errorField.push('ชื่อผู้ใช้งาน');
      }

      if (this.createMode && (this.user.password === null || this.user.password.length === 0)) {
        errorField.push('รหัสผ่าน');
      }

      if (errorField.length > 0) {
        this.errorMessage = 'โปรดกรอก ' + errorField.join(', ') + ' ให้ถูกต้อง';
        return;
      }
    } else {
      const errorField = [];
      this.errorMessage = null;

      if (this.user.username === null || this.user.username.length === 0) {
        errorField.push('Username');
      }

      if (this.createMode && (this.user.password == null || this.user.password.length == 0)) {
        errorField.push('Password');
      }

      if (errorField.length > 0) {
        this.errorMessage = 'Please fill ' + errorField.join(', ') + ' correctly';
        return;
      }
    }

    let request = null;

    if (this.createMode) {
      request = this.apiService.createUser(this.user);
    } else {
      request = this.apiService.updateUser(this.user);
    }

    request.subscribe(
      (obj) => {
        this.parent.reload();
        this.modalRef.hide();
        this.clearData();
      },
      error => {
        this.checkErrorMsg(error);
      }
    );
  }

  checkErrorMsg(error) {
    // this.errorMsgForShow = this.apiService.getErrorMessage(error);
    if (this.translate.currentLang === 'en') {
      this.errorMessage = 'This username have already exists';

    } else {
      this.errorMessage = 'ชื่อผู้ใช้นี้มีอยู่แล้ว';
    }
  }

  clearData() {
    this.user = {};
    this.errorMsgForShow = null;
  }

  close() {
    this.modalRef.hide();
    this.clearData();
  }


  disableUser() {
    const confirmFlag = confirm(this.translate.instant('NORMAL.DELETE-USER-CONFIRM'));
    if (confirmFlag) {
      const request = this.apiService.deleteUser(this.user);
      request.subscribe(
        (obj) => {
          this.parent.reload();
          this.modalRef.hide();
        },
        error => {
          // this.errorMessage = this.apiService.getErrorMessage(error);
          this.checkErrorallMsg(error);
        }
      );
    }
  }

  checkErrorallMsg(error) {
    this.errorMsgForShows = this.apiService.getErrorMessage(error);
    if (this.translate.currentLang === 'en') {
      this.errorMessage = this.errorMsgForShows[0];
    } else {
      this.errorMessage = this.errorMsgForShows[1];
    }
  }

  enableUser() {
    this.user.is_active = true;
    const request = this.apiService.updateUser(this.user);

    request.subscribe(
      (obj) => {
        this.parent.reload();
        this.modalRef.hide();
      },
      error => {
        this.errorMessage = this.apiService.getErrorMessage(error);
      }
    );
  }

  loadGroups() {
    //   this.groups = [];
    //
    //   this.apiService.getGroups().subscribe(
    //     (groups) => {
    //       _.forEach(groups, (group) => {
    //         this.groups.push({
    //           'label': group['name'],
    //           'value': group['id']
    //         })
    //       })
    //     },
    //     error => {
    //     }
    //   );
  }
}
