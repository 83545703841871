import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import * as _ from  'lodash';
import {Chart} from 'chart.js';
import {HttpParams} from '@angular/common/http';



@Component({
  selector: 'app-dashborad-cloud-usage',
  templateUrl: './dashborad-cloud-usage.component.html',
  styleUrls: ['./dashborad-cloud-usage.component.scss']
})
export class DashboradCloudUsageComponent implements OnInit {

  monthList = [];
  yearList: any[] = [];
  company: any;
  selectedYear: any;
  total = 0;

  charts_signing_counter_bar_stacked: any = null;
  charts_signing_counter_bar_stacked_title = '';
  selectedMonth = 1;
  monthNames = [
    'MONTH.JANUARY',
    'MONTH.FEBRUARY',
    'MONTH.MARCH',
    'MONTH.APRIL',
    'MONTH.MAY',
    'MONTH.JUNE',
    'MONTH.JULY',
    'MONTH.AUGUST',
    'MONTH.SEPTEMBER',
    'MONTH.OCTOBER',
    'MONTH.NOVEMBER',
    'MONTH.DECEMBER'
  ];

  constructor(
    private translate: TranslateService,
    private apiService: ApiService
  ) {
  }

  ngOnInit() {
    this.translate.get('COMMON.ALL').subscribe(() => {
      this.populateDropdown();
      this.loadMonthList();
      this.loadStorageData();
    });
    this.translate.onLangChange.subscribe((event: LangChangeEvent ) => {
      this.loadMonthList();
      this.loadStorageData();
    });
  }

  loadMonthList() {
    this.monthList = this.monthNames.map(monthName => this.translate.instant(monthName));
  }

  populateDropdown() {
    const currentDate = new Date();
    // populate year list back within 10 years
    const thisYear = currentDate.getFullYear();
    for (let i = 0; i <= 10; i++) {
      this.yearList.push({label: (thisYear - i).toString(), value: thisYear - i});
    }

    // set current month and year
    this.selectedMonth = currentDate.getMonth() + 1;
    this.selectedYear = thisYear;
    // this.loadStorageData();
  }

//
  // load data from current month
  loadStorageData() {
    // const storage = [3, 10, 12, 17, 0, 0, 0, 0, 0, 0, 0, 0];
    let params = new HttpParams();

    if (this.selectedYear) {
      params = params.append('year', this.selectedYear);
    }
    this.apiService.getMonthleStorage(params).subscribe(
      data => {
        this.total = Math.max(...data.storage) * 2;
        this.create_count_bar_storage(data.storage);
        // this.create_count_bar_storage(storage);
      });



    Chart.plugins.register({
      beforeDraw: function (chart) {
        if (chart.config.options.elements.center) {
          chart.clear();
          //  Get ctx from string
          const ctx = chart.chart.ctx;

          //  Get options from the center object in options
          const centerConfig = chart.config.options.elements.center;
          const fontStyle = centerConfig.fontStyle || 'Arial';
          const txt = centerConfig.text;
          const color = centerConfig.color || '#000';
          const sidePadding = centerConfig.sidePadding || 20;
          const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
          //  Start with a base font of 30px
          ctx.font = '30px ' + fontStyle;
          const stringWidth = ctx.measureText(txt).width;
          const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

          // Find out how much the font can grow in width.
          const widthRatio = elementWidth / stringWidth;
          const newFontSize = Math.floor(30 * widthRatio);
          const elementHeight = (chart.innerRadius * 2);

          // Pick a new font size so it will not be larger than the height of label.
          const fontSizeToUse = Math.min(newFontSize, elementHeight);

          //  Set font settings to draw it correctly.
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
          const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 1.2);
          ctx.font = fontSizeToUse + 'px ' + fontStyle;
          ctx.fillStyle = color;

          //  Draw text in center
          ctx.fillText(txt, centerX, centerY);
        }
      },

      afterDraw: function (chart) {
        if (chart.data.datasets.length === 0) {
          // No data is present
          const ctx = chart.chart.ctx;
          const width = chart.chart.width;
          const height = chart.chart.height;
          chart.clear();

          ctx.save();
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.font = '14pt Tahoma';
          ctx.fillStyle = '#000';
          ctx.fillText('ไม่ข้อมูลแสดง', width / 2, height / 2);
          ctx.restore();
        }
      }
    });
  }

  create_count_bar_storage(data) {
    const storageData = data;
    const color = Chart.helpers.color;
    // const storageData = [0, 0, 0, 0, 5, 7, 8, 9, 10, 0, 0, 0];
    const storageList = [storageData[0]];
    let difference: any;
    for (let i = 0; i < storageData.length; i++) {
      difference = storageData[i + 1] - storageData[i];
      if (difference === 0) {
        storageList.push(0);
      } else if (difference >= 0) {
        storageList.push(difference);
      } else {
        break;
      }
    }

    const layers = [];
    for (let i = 0; i < storageList.length; i++) {
      layers.push(Array(i).fill(0).concat(Array(storageList.length - i).fill(storageList[i])));
    }

    this.charts_signing_counter_bar_stacked_title = 'Storage Dashboard';
    const barChartData = {
      labels: this.monthList,
      datasets: [
        // {
        //   label: this.translate.instant('CHART.STORAGE'),
        //   // backgroundColor: color('rgb(54, 162, 235)').alpha(0.5).rgbString(),
        //   backgroundColor: color('rgb(255, 77, 124)').alpha(0.5).rgbString(),
        //   borderColor: 'rgb(54, 162, 235)',
        //   data: storage
        // },
        {
          label: this.translate.instant('MONTH.JANUARY'),
          backgroundColor: color('rgb(192, 0, 0)').alpha(0.5).rgbString(),
          borderColor: 'rgb(192, 0, 0)',
          data: layers[0]
        },
        {
          label: this.translate.instant('MONTH.FEBRUARY'),
          backgroundColor: color('rgb(167, 0, 100)').alpha(0.5).rgbString(),
          borderColor: 'rgb(167, 0, 100)',
          data: layers[1]
        }, {
          label: this.translate.instant('MONTH.MARCH'),
          backgroundColor: color('rgb(67, 0, 134)').alpha(0.5).rgbString(),
          borderColor: 'rgb(67, 0, 134)',
          data: layers[2]
        }, {
          label: this.translate.instant('MONTH.APRIL'),
          backgroundColor: color('rgb(0, 51, 153)').alpha(0.5).rgbString(),
          borderColor: 'rgb(0, 51, 153)',
          data: layers[3]
        }, {
          label: this.translate.instant('MONTH.MAY'),
          backgroundColor: color('rgb(3, 212, 219)').alpha(0.5).rgbString(),
          borderColor: 'rgb(3, 212, 219)',
          data: layers[4]
        }, {
          label: this.translate.instant('MONTH.JUNE'),
          backgroundColor: color('rgb(0, 216, 0)').alpha(0.5).rgbString(),
          borderColor: 'rgb(0, 216, 0)',
          data: layers[5]
        }, {
          label: this.translate.instant('MONTH.JULY'),
          backgroundColor: color('rgb(183, 247, 0 )').alpha(0.5).rgbString(),
          borderColor: 'rgb(183, 247, 0 )',
          data: layers[6]
        }, {
          label: this.translate.instant('MONTH.AUGUST'),
          backgroundColor: color('rgb(233, 215, 0 )').alpha(0.5).rgbString(),
          borderColor: 'rgb(233, 215, 0 )',
          data: layers[7]
        }, {
          label: this.translate.instant('MONTH.SEPTEMBER'),
          backgroundColor: color('rgb(234, 94, 0)').alpha(0.5).rgbString(),
          borderColor: 'rgb(234, 94, 0)',
          data: layers[8]
        }, {
          label: this.translate.instant('MONTH.OCTOBER'),
          backgroundColor: color('rgb(222, 163, 68)').alpha(0.5).rgbString(),
          borderColor: 'rgb(222, 163, 68)',
          data: layers[9]
        }, {
          label: this.translate.instant('MONTH.NOVEMBER'),
          backgroundColor: color('rgb(224, 76, 242)').alpha(0.5).rgbString(),
          borderColor: 'rgb(224, 76, 242)',
          data: layers[10]
        }, {
          label: this.translate.instant('MONTH.DECEMBER'),
          backgroundColor: color('rgb(55, 226, 148)').alpha(0.5).rgbString(),
          borderColor: 'rgb(55, 226, 148)',
          data: layers[11]
        }
      ]
    };


    if (this.charts_signing_counter_bar_stacked == null) {
      this.charts_signing_counter_bar_stacked = new Chart('storage-counter-bar-chart', {
        type: 'bar',
        data: barChartData,
        options: {
          tooltips: {
            titleFontSize: 17,
            bodyFontSize: 17,
            mode: 'index',
            intersect: false,
            callbacks: this.getFormatLabel('GB', true),
             filter: function (tooltipItem) {
              return tooltipItem.datasetIndex === tooltipItem.index;
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true,
            }],
            yAxes: [{
              stacked: true,
              ticks: {
                beginAtZero: true,
                max: this.total,
                callback: function (value) {
                  if (value % 1 === 0) {
                    return value;
                  }
                }
              }
            }]
          },
          legend: {
            position: 'top',
          }
        }
      });
    } else {
      this.charts_signing_counter_bar_stacked.config.options.tooltips.callbacks =
        this.getFormatLabel('GB', true);

      this.charts_signing_counter_bar_stacked.data = barChartData;
      this.charts_signing_counter_bar_stacked.update();
    }
  }

  getFormatLabel(unitName, showSum) {
    const tooltip = {
      label: function (tooltipItem, data) {

        let dataLabel = data.datasets[tooltipItem.datasetIndex].label;
        if (dataLabel == null) {
          dataLabel = data.labels[tooltipItem.index];
        }

        const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        const valueStr = ' : ' + value.toLocaleString();
        dataLabel += valueStr + ' ' + unitName;

        if (showSum) {
          const text = data.datasets.reduce((accumulator, val) => {
           return  val.data[tooltipItem.index] ? accumulator + val.data[tooltipItem.index] : accumulator;
          }, 0);
          window['total'] += text;
        }

        return dataLabel;
      }
    };

    if (showSum) {
      tooltip['afterTitle'] = function () {
        window['total'] = 0;
      };

      tooltip['footer'] = (tooltipItem, data) => {
        const text = [this.translate.instant('CHART.TOTAL') + ' : ' + window['total'].toLocaleString() + ' ' + unitName +
        ((window['total'] >= 2 && unitName === 'Time') ? 's' : '')];
        return tooltipItem.length ? text : null;
      };
    }
    return tooltip;
  }


}
