import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SearchCustomerComponent} from './search-customer.component';
import {AutoCompleteModule, CalendarModule, CheckboxModule, DropdownModule, TableModule} from 'primeng';
import {FormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreModule} from '../../core/core.module';
import {TranslateModule} from '@ngx-translate/core';
import {ModalModule} from 'ngx-bootstrap';



@NgModule({
  declarations: [SearchCustomerComponent],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    CalendarModule,
    CheckboxModule,
    AutoCompleteModule,
    ModalModule.forRoot(),
    NgbModule,
    CoreModule,
    TableModule,
    TranslateModule
  ]
})
export class SearchCustomerModule { }
