import * as moment from 'moment';

export class DailyReport {
  static FILTER_COMPANY_CODE = [
    {label: 'Siam Furukawa Co.,Ltd.', value: '1000'},
    {label: 'Siam Furukawa Trading Co.,Ltd.', value: '2000'}
  ];

  static FILTER_COMPANY_CODE_SHORT = [
    {label: 'SFC', value: '1000'},
    {label: 'SFT', value: '2000'}
  ];

  companyCode: string;
  importDate: Date;
  documentCount: number;
  selected: boolean;
  exportFileName: string;

  constructor(jsonObject: any) {
    this.companyCode = jsonObject.company;
    this.importDate = new Date(jsonObject.import_date);
    this.documentCount = jsonObject.document_count;
    this.selected = false;
    this.exportFileName = this.getExportFileName();
  }

  getLabelCompanyCode(): string {
    const obj = DailyReport.FILTER_COMPANY_CODE.find(item => item.value === this.companyCode);
    return obj.label;
  }

  getLabelCompanyCodeShort(): string {
    const obj = DailyReport.FILTER_COMPANY_CODE_SHORT.find(item => item.value === this.companyCode);
    return obj.label;
  }

  getImportDateToStr(): string {
    return moment(this.importDate).format('YYYY-MM-DD');
  }

  private getExportFileName(): string {
    return `daily_report_${this.getLabelCompanyCodeShort()}_${this.getImportDateToStr()}.xls`;
  }
}
