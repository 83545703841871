import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import {SelectItem} from 'primeng/api';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../services/authentication.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard-list',
  templateUrl: './dashboard-list.component.html',
  styleUrls: ['./dashboard-list.component.scss']
})
export class DashboardListComponent implements OnInit {

  // month amount to show in graph
  monthAmount = 6;
  // activity amount to show in activity list
  activityAmount = 2;
  monthList = [];
  monthListDropdown = [
    {label: 'มกราคม', value: 1},
    {label: 'กุมภาพันธ์', value: 2},
    {label: 'มีนาคม', value: 3},
    {label: 'เมษายน', value: 4},
    {label: 'พฤษภาคม', value: 5},
    {label: 'มิถุนายน', value: 6},
    {label: 'กรกฎาคม', value: 7},
    {label: 'สิงหาคม', value: 8},
    {label: 'กันยายน', value: 9},
    {label: 'ตุลาคม', value: 10},
    {label: 'พฤศจิกายน', value: 11},
    {label: 'ธันวาคม', value: 12}
  ];
  SelectBashboard = [
    {label: 'Documents Dashboard', value: 1},
    {label: 'SMS Dashboard', value: 2},
    {label: 'Emails Dashboard', value: 3},
    {label: 'Document Request Dashboard', value: 4},
  ];
  dashboards: SelectItem[];
  selectDashboard: string;
  Dashboard:any[];
  yearList: any[] = [];
  selectedCompany: SelectItem[];
  company: any;
  selectedHomeproGroup: SelectItem[];
  homeproGroup: any;
  selectedMonth: any;
  selectedYear: any;
  activityList: any;
  selectedDate: Date = new Date();

  charts_bar_stacked: any = null;
  charts_signing_counter_bar_stacked: any = null;
  charts_signing_counter_line: any = null;
  chartLabels = [];
  monthly_loading = false;
  daily_loading = false;
  bar_stacked_title = "";
  charts_signing_counter_bar_stacked_title = "";
  charts_transaction_count_title = "";
  isShow = false;
  keepdashboard :any;
  defaultLabel :any;
  constructor(
    private apiService: ApiService,
    private router: Router,
    private authService: AuthenticationService,
    private translate: TranslateService) {
    if (this.authService.isAccountant()) {
      this.router.navigate(['/taxReport']);
    }
  }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
       this.loaddashboard();
    })
    this.loaddashboard();
  }

  loaddashboard(){
    this.keepdashboard = null;
    this.dashboards = [
           {label: this.translate.instant('DASHBOARD.TITLE')
             , value: null},
           {label: this.translate.instant('DASHBOARD.CLOUD-USAGE'), value: 2}
        ];


  }
  choosedashboard(){
    this.keepdashboard = this.selectDashboard;
  }




}

