<div class="limiter">
  <div class="container-login100" style="background-image: url('/assets/images/demo1.jpg');">
    <div class="wrap-login100">
      <form class="login100-form validate-form">
        <div class="fix-margin">
					<span class="login100-form-logo">
            <br>
            <img src="/assets/images/fb-logo.png">
					</span>
          <span class="login100-form-title p-b-34 p-t-27">
						Login
					</span>
          <ng-container *ngIf="loginMode">
            <div class="wrap-input100 validate-input text-center" data-validate="Enter username">
              <i class="fas fa-user"></i>
              <input class="input100" autocomplete="off" type="text" id="username" name="username"
                     placeholder="Username"
                     [(ngModel)]="username">
            </div>

            <div class="wrap-input100 validate-input" data-validate="Enter password">
              <i class="fas fa-lock"></i>
              <input class="input100" autocomplete="off" type="password" id="password" name="password"
                     placeholder="Password"
                     [(ngModel)]="password">
            </div>
            <div class="text-yellow text-center">
              <i class="fas fa-exclamation-circle" *ngIf="errorMessage"></i>
              {{ errorMessage ? errorMessage : '&nbsp;' }}
            </div>
            <br>
            <div class="container-login100-form-btn">
              <button class="login100-form-btn" (click)="login()">
                <i class="fas fa-sign-in-alt"></i>
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="forgotPasswordMode">
            <div class="wrap-input100 validate-input" data-validate="Enter username">
              <i class="fas fa-envelope"></i>
              <input class="input100" autocomplete="off" type="text" name="email" placeholder="Email"
                     [(ngModel)]="email">
            </div>
            <div class="text-yellow text-center">
              <i class="fas fa-exclamation-circle" *ngIf="errorMessage"></i>
              {{ errorMessage ? errorMessage : '&nbsp;' }}
            </div>
            <br>
            <div class="container-login100-form-btn">
              <button name="buttonForTesting" class="login100-form-btn" href="javascript:void(0)"
                      (click)="forgotPassword()">
                <i class="fas fa-paper-plane"></i>
              </button>
            </div>
          </ng-container>
          <br>
          <div class="text-center p-t-90">
            <a (click)="forgotPasswordMode = true; loginMode = false" class="stretched-link text-white"
               href="javascript:void(0)" *ngIf="loginMode">
              Forgot Password?
            </a>
            <br>
            <p *ngIf="!loginMode" class="text-white">{{ passwordResetText}}</p>
            <br>
            <div class="container-login100-form-btn">
              <button name="buttonForTesting" class="login100-form-btn" (click)="gotoLogin()"
                      *ngIf="resetPasswordSuccessMode">
                Back to login
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
