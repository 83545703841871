<div class="limiter">
  <div class="container-login100" style="background-image: url('/assets/images/demo1.jpg');">
    <div class="wrap-login100">
      <form class="login100-form validate-form">
					<span class="login100-form-logo">
            <img src="/assets/images/fb-logo.png">
					</span>
        <span class="login100-form-title p-b-34 p-t-27">
          Reset Password
        </span>
        <br>
        <ng-container>
          <div class="">
            <div class="wrap-input100 validate-input" data-validate="Enter username">
              <input class="input100" type="password" id="password1" name="password" placeholder="New password"
                     [(ngModel)]="password1 ">
            </div>
          </div>
          <div class="wrap-input100 validate-input" data-validate="Enter username">
            <input class="input100" type="password" id="password2" name="password" placeholder="Confirm password"
                   [(ngModel)]="password2">
          </div>
          <div class="text-yellow text-center">
            <i class="fas fa-exclamation-circle" *ngIf="errorMessage"></i>
            {{ errorMessage ? errorMessage : '&nbsp;' }}
          </div>
          <br>
          <div class="container-login100-form-btn">
            <button name="buttonForTesting" class="login100-form-btn" href="javascript:void(0)"
                    (click)="changePassword()">
              Change Password
            </button>
          </div>
        </ng-container>
      </form>
    </div>
  </div>
</div>
