import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-filter-header',
  templateUrl: './filter-header.component.html',
  styleUrls: ['./filter-header.component.scss']
})
export class FilterHeaderComponent implements OnInit {
  @Input() loading: boolean;
  @Output() search = new EventEmitter();
  @Output() clear = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }
}
