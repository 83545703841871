import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from './api.service';
import {API_CONSTANT, API_URL} from './api.constant';
import * as _ from 'lodash';

@Injectable()
export class AuthenticationService {

  constructor(
    private http: HttpClient,
    private apiService: ApiService) {
  }

  login(username: string, password: string) {
    return this.http.post(API_CONSTANT.login, {
      username,
      password
    });
  }
  confirmPassword(newPassword) {
    return this.http.post(API_URL.confirm_password, newPassword);
  }
  forgotPassword(data) {
    return this.http.post(API_URL.password_reset, data);
  }
  validateResetPasswordToken(data) {
    return this.http.post(API_URL.reset_password_validate, data);
  }
  requestOtp(email: string) {
    return this.http.post(API_CONSTANT.request_otp, {
      email,
    });
  }  validateOTP(password: string) {
    return this.http.post(API_CONSTANT.validate_otp, {
      password,
    });
  }

  updateUserInfo(user) {
    localStorage.setItem('userID', _.get(user, 'id'));
    localStorage.setItem('username', _.get(user, 'username'));
    localStorage.setItem('email', _.get(user, 'email'));
    localStorage.setItem('firstName', _.get(user, 'first_name'));
    localStorage.setItem('lastLame', _.get(user, 'last_name'));
    localStorage.setItem('isSuperUser', _.get(user, 'is_superuser'));

    if (_.get(user, 'is_superuser')) {
      localStorage.setItem('group', 'Admin');
    } else {
      localStorage.setItem('group', 'User');
    }
  }
  reloadUserInfo() {
    if (this.isLoggedIn()) {
      this.apiService.getMe().subscribe(
        (user: any) => {
          this.updateUserInfo(user);
        }
      );
    }
  }

  isLoggedIn() {
    return localStorage.getItem('currentUser') != null;
  }

  isAdmin() {
    return localStorage.getItem('group') === 'Admin' || localStorage.getItem('isSuperUser') === 'true';
  }

  isAccountant() {
    return localStorage.getItem('group') === 'Accountant';
  }

  isAccountantAdmin() {
    return localStorage.getItem('group') === 'Accountant Admin';
  }

  isIT() {
    return localStorage.getItem('group') === 'IT';
  }

  isCallCenter() {
    return localStorage.getItem('group') === 'Call Center';
  }

  isUIH() {
    return localStorage.getItem('group') === 'UIH';
  }

  isCustomer() {
    return localStorage.getItem('group') === 'Customer';
  }

  isUser() {
    return localStorage.getItem('group') === 'User' || localStorage.getItem('isSuperUser') === 'false';
  }
}

