import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {CustomPaginationComponent} from './pagination/custom-pagination.component';
import {DropdownModule} from 'primeng';
import {FilterHeaderComponent} from "./filter-header/filter-header.component";

@NgModule({
  declarations: [
    CustomPaginationComponent,
    FilterHeaderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbPaginationModule,
    BrowserModule,
    FormsModule,
    TranslateModule,
    DropdownModule
  ],
  exports: [
    CustomPaginationComponent,
    FilterHeaderComponent
  ]
})
export class CoreModule {
}
