<div class="header">
  <h2>{{'PROFILE.PASSWORD.TITLE' | translate}}</h2>
  <hr>
</div>

<div class="m-t-20 form-center">
  <div class="form-group row">
    <label class="col-sm-3 col-form-label">{{'PROFILE.PASSWORD.OLD-PASSWORD' | translate}}</label>
    <div class="col-sm-9">
      <input type="password" class="form-control" placeholder="{{'PROFILE.PASSWORD.OLD-PASSWORD' | translate}}" [(ngModel)]="oldPassword">
    </div>
  </div>
  <hr>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label">{{'PROFILE.PASSWORD.NEW-PASSWORD' | translate}}</label>
    <div class="col-sm-9">
      <input type="password" class="form-control" placeholder="{{'PROFILE.PASSWORD.NEW-PASSWORD' | translate}}" [(ngModel)]="newPassword">
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label">{{'PROFILE.PASSWORD.CONFIRM-NEW-PASSWORD' | translate}}</label>
    <div class="col-sm-9">
      <input type="password" class="form-control" placeholder="{{'PROFILE.PASSWORD.CONFIRM-NEW-PASSWORD' | translate}}" [(ngModel)]="confirmPassword">
      <p class="text-red" *ngIf="errorMessage">{{errorMessage}}</p>
    </div>
  </div>
  <div class="text-right">
    <button class="btn btn-primary" (click)="submit()">{{'PROFILE.PASSWORD.CONFIRM' | translate}}</button>
  </div>
</div>
