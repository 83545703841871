import {Component, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {ProfileListDetailComponent} from '../profile-list-detail/profile-list-detail.component';
import * as _ from 'lodash';
import {HttpParams} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'profile-list',
  templateUrl: './profile-list.component.html',
  styleUrls: ['./profile-list.component.scss'],
})
export class ProfileListComponent implements OnInit {
  itemsPerPage = 40;

  searchUsername: any = '';
  searchGroup: any;
  groups: any = [];
  totalItems: number;
  currentPage = 1;
  loading = false;
  list: any = [];

  // @ts-ignore
  @ViewChild('detailModal') detailModal: ProfileListDetailComponent

  constructor(private apiService: ApiService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.translate.onLangChange.subscribe((res: string) => {
      this.loadGroups();
    });
    this.pageChanged();
    this.loadGroups();
  }

  reload() {
    this.pageChanged();
  }

  pageChanged() {
    this.loadData();
  }

  optionChanged() {
    this.search();
  }

  openModal(data, index) {
    this.detailModal.open(this, data);
  }

  search() {
    this.currentPage = 1;
    this.loadData();
  }

  loadData() {
    const data = {
      page: this.currentPage,
      page_size: this.itemsPerPage,
      search: this.searchUsername
    };

    if (this.searchGroup != null)
      data['group'] = this.searchGroup;

    this.loading = true;
    this.apiService.getUsers(data).subscribe(
      (res: any) => {
        this.list = res.results;
        this.totalItems = res.count;
        this.loading = false;
      },
      error => {
        this.loading = false;
      }
    );
  }

  clearSearch() {
    this.searchGroup = null;
    this.searchUsername = '';
    this.loadData();
  }

  loadGroups() {
    // this.groups = [{
    // 'label': this.translate.instant('COMMON.ALL'),
    //   'value': null
    // }];
    //
    // this.apiService.getGroups().subscribe(
    //   (groups) => {
    //     _.forEach(groups, (group) => {
    //       this.groups.push({
    //         'label': group['name'],
    //         'value': group['id']
    //       })
    //     })
    //     this.groups = _.uniqBy(this.groups,'label');
    //   },
    //   error => {
    //   }
    // )
  }
}
