export class Document {
  static FILTER_DOC_TYPE = [
    {label: 'Invoice', value: 'INV'},
    {label: 'Credit Note', value: 'CND'},
    {label: 'Debit Note', value: 'DND'},
    {label: 'WHT certificate', value: 'WHT'}
  ];

  static FILTER_COMPANY_CODE = [
    {label: 'Siam Furukawa Co.,Ltd.', value: '1000'},
    {label: 'Siam Furukawa Trading Co.,Ltd.', value: '2000'}
  ];

  id: number;
  rawFilename: string;
  docType: string;
  companyCode: string;
  year: string;
  docNumber: string;
  createdTime: Date;
  filepath: string;
  status: string;
  selected: boolean;
  cancel_filepath: string;

  constructor(jsonObject: any) {
    this.id = jsonObject.id;
    this.rawFilename = jsonObject.raw_filename;
    this.docType = jsonObject.doc_type;
    this.companyCode = jsonObject.company_code;
    this.year = jsonObject.year;
    this.docNumber = jsonObject.doc_number;
    this.createdTime = new Date(jsonObject.created_time);
    this.filepath = jsonObject.filepath;
    this.status = jsonObject.cancel ? 'Inactive' : 'Active';
    this.cancel_filepath = jsonObject.cancel_filepath;
    this.selected = false;
  }

  getLabelDocType(): string {
    const obj = Document.FILTER_DOC_TYPE.find(item => item.value === this.docType);
    return obj.label;
  }

  getLabelCompanyCode(): string {
    const obj = Document.FILTER_COMPANY_CODE.find(item => item.value === this.companyCode);
    return obj.label;
  }

  isCancel(): boolean {
    return this.status === 'Inactive';
  }

  getLinkToFile(): string {
    if (this.status == 'Active') {
      return this.filepath;
    } else if (this.status == 'Inactive') {
      return this.cancel_filepath;
    }
  }
}
