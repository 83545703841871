import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DailyReportComponent} from './daily-report.component';


export const dailyReportRoutes: Routes = [
  {
    path: 'daily-report',
    component: DailyReportComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(dailyReportRoutes)],
  exports: [RouterModule]
})
export class DailyReportRoutingModule { }
