import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {HttpClientService} from './http-client.service';
import {API_CONSTANT, API_URL} from './api.constant';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class ApiService {

  getErrorMessage(error) {
    if (error != null && error.error != null && error.error.message != null && error.error.message.length > 0) {
      return error.error.message.join(',');
    } else {
      return 'เกิดข้อผิดพลาด โปรดลองอีกครั้ง';
    }
  }

  createDownloadFile(blob, filename) {
    // for IE10+
    if (typeof navigator !== 'undefined' && navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const element = document.createElement('a');
      document.body.appendChild(element);

      const url = window.URL.createObjectURL(blob);
      element.setAttribute('href', url);
      element.setAttribute('download', filename);

      element.style.display = 'none';
      element.click();

      document.body.removeChild(element);
    }
  }

  ab2str(buf) {
    return String.fromCharCode.apply(null, new Uint16Array(buf));
  }

  parseErrorBlob(err: HttpErrorResponse): Observable<any> {
    const reader: FileReader = new FileReader();

    const obs = new Observable((observer: any) => {
      reader.onloadend = (e) => {
        try {
          observer.error({error: JSON.parse(this.ab2str(reader.result))});
        } catch (e) {
          observer.error({error: null});
        }

        observer.complete();
      };
    });
    reader.readAsText(err.error);
    return obs;
  }

  constructor(private localHttp: HttpClient,
              private http: HttpClientService) {
  }

  confirmPassword(newPassword) {
    return this.http.patch(API_URL.confirm_password, newPassword);
  }

  updatePassword(newPassword) {
    return this.http.patch(API_CONSTANT.me_set_password, newPassword);
  }

  uploadCSV(document) {
    return this.http.post(API_CONSTANT.upload_csv, document);
  }

  signSignature(document) {
    return this.http.postBlob(API_CONSTANT.sign_docs, document).pipe(catchError(this.parseErrorBlob));
  }

  getBackgroundJobStatus(id) {
    return this.http.get(API_CONSTANT.background_jobs + id + '/');
  }

  getTaxDataResult(data, params?) {
    let url = API_CONSTANT.tax_data_upload_result;

    if (params != null) {
      url += '?' + params;
    }

    return this.http.post(url, data);
  }

  postDownloadFile(data) {
    return this.http.post(API_CONSTANT.mark_download, data);
  }

  postSummary(data) {
    return this.http.post(API_CONSTANT.summary_csv, data);
  }

  getSalesTaxReport(data) {
    return this.http.postBlob(API_CONSTANT.sales_tax_report, data).pipe(catchError(this.parseErrorBlob));
  }

  getZip2MB(data) {
    return this.http.postBlob(API_CONSTANT.zip_file_two_megabyte, data).pipe(catchError(this.parseErrorBlob));
  }

  getZip(data) {
    return this.http.getBlob(API_CONSTANT.zip_file, data).pipe(catchError(this.parseErrorBlob));
  }

  getFileFullPath(full_path) {
    return this.http.getBlob(full_path).pipe(catchError(this.parseErrorBlob));
  }

  postSearch(data, params?) {
    let url = API_CONSTANT.search_files;

    if (params != null) {
      url += '?' + params;
    }

    return this.http.post(url, data);
  }

  postSearch1(data, params?) {  // dummy function to make old code working
    return this.postSearch(data, params);
  }

  sign_counter(data, params?) {
    return this.http.post(API_CONSTANT.sign_counter + '?' + params, data);
  }

  getUsers(params?) {
    return this.http.get(API_CONSTANT.users, params);
  }

  createUser(data) {
    return this.http.post(API_CONSTANT.users, data);
  }

  updateUser(data) {
    return this.http.patch(API_CONSTANT.users + data.id + '/', data);
  }

  deleteUser(data) {
    return this.http.delete(API_CONSTANT.users + data.id + '/');
  }

  getGroups(params?) {
    return this.http.get(API_CONSTANT.groups, params);
  }

  getMe(params?) {
    return this.http.get(API_CONSTANT.me, params);
  }

  getDashboardMonthlyData(data) {
    return this.http.get(API_CONSTANT.dashboard_monthly, data);
  }

  getDashboardSMSMonthlyData(data) {
    return this.http.get(API_CONSTANT.dashboard_sms_monthly, data);
  }

  getDashboardSignCounterMinute(data) {
    return this.http.get(API_CONSTANT.dashboard_sign_counter_minute, data);
  }

  getDashboardLengthData(data) {
    return this.http.get(API_CONSTANT.dashboard_length, data);
  }

  getDashboardActivity(data) {
    return this.http.get(API_CONSTANT.dashboard_activity, data);
  }

  getCompany() {
    return this.http.get(API_CONSTANT.corporates);
  }

  getCompanyGroup() {
    return this.http.get(API_CONSTANT.group_corporates);
  }

  getBranch() {
    return this.http.get(API_CONSTANT.branches);
  }

  getDocumentType() {
    return this.http.get(API_CONSTANT.document_type);
  }

  postSaleTax(data, params?) {
    let url = API_CONSTANT.sales_tax_view;

    if (params != null) {
      url += '?' + params;
    }

    return this.http.post(url, data);
  }

  postSaleZipTax(data, params?) {
    let url = API_CONSTANT.sales_zip_tax;

    if (params != null) {
      url += '?' + params;
    }

    return this.http.post(url, data);
  }

  getMissingDoc(params) {
    return this.http.getBlob(API_CONSTANT.export_missing_excel, params).pipe(catchError(this.parseErrorBlob));
  }

  approveSale(params) {
    return this.http.post(API_CONSTANT.approve_sale_tax, params);
  }

  approveUploadSale(params) {
    return this.http.post(API_CONSTANT.approve_upload_sale, params);
  }

  getAllDocs(params) {
    return this.http.getBlob(API_CONSTANT.export_all_docs, params).pipe(catchError(this.parseErrorBlob));
  }

  downloadFileFromMediaPath(url) {
    return this.http.getBlob(API_CONSTANT.MEDIA_PATH_URL + url).pipe(catchError(this.parseErrorBlob));
  }

  getAllZipFile(params) {
    return this.http.postBlob(API_CONSTANT.export_zip_file, params).pipe(catchError(this.parseErrorBlob));
  }

  getExcelReport(params) {
    return this.http.getBlob(API_CONSTANT.export_monthly_excel_report, params).pipe(catchError(this.parseErrorBlob));
  }

  getEmailReport(params?) {
    return this.http.get(API_CONSTANT.email_report, params);
  }

  getEmailDashboard(params?) {
    return this.http.get(API_CONSTANT.email_report, params);
  }


  getBuyer(params?) {
    return this.http.get(API_CONSTANT.buyer, params);
  }

  postB2PDocumentSummary(data, params?) {
    let url = API_CONSTANT.b2p_document_summary;

    if (params != null) {
      url += '?' + params;
    }
    return this.http.post(url, data);
  }

  postB2PDocumentSummaryDetail(data, params?) {
    let url = API_CONSTANT.b2p_document_summary_detail;

    if (params != null) {
      url += '?' + params;
    }
    return this.http.post(url, data);
  }

  downloadB2PDocumentSummaryDetailExcel(data, params?) {
    let url = API_CONSTANT.b2p_document_summary_detail_excel;

    if (params != null) {
      url += '?' + params;
    }

    return this.http.postBlob(url, data).pipe(catchError(this.parseErrorBlob));
  }

  getBillingExcelReport(params) {
    return this.http.getBlob(API_CONSTANT.export_billing_monthly_excel_report, params).pipe(catchError(this.parseErrorBlob));
  }

  getBulkAllDocs(params) {
    return this.http.getBlob(API_CONSTANT.export_bulk_all_docs, params).pipe(catchError(this.parseErrorBlob));
  }

  getBulkMissingDoc(params) {
    return this.http.getBlob(API_CONSTANT.export_bulk_missing_excel, params).pipe(catchError(this.parseErrorBlob));
  }

  postBulkSaleTax(data, params?) {
    let url = API_CONSTANT.bulk_sales_tax_view;

    if (params != null) {
      url += '?' + params;
    }

    return this.http.post(url, data);
  }

  searchDocuments(params?) {
    let url = API_CONSTANT.search_documents;
    if (params != null) {
      url += '?' + params;
    }
    return this.http.get(url);
  }

  downloadDocument(url) {
    return this.http.getBlob(url).pipe(catchError(this.parseErrorBlob));
  }

  cancelNoRef(data) {
    return this.http.post(API_CONSTANT.cancel_no_ref, data);
  }

  getErrors(params?) {
    return this.http.get(API_CONSTANT.import_document, params);
  }

  getMonthleCount(data) {
    return this.http.get(API_CONSTANT.dashboard_monthly_count, data);
  }

  getMonthleStorage(params?) {
    return this.http.get(API_CONSTANT.dashboard_monthly_storage, params);
  }

  forgotPassword(data) {
    return this.http.post(API_CONSTANT.password_reset, data);
  }

  dailyReportDocument(params?) {
    let url = API_CONSTANT.daily_report_document;
    if (params != null) {
      url += '?' + params;
    }
    return this.http.get(url);
  }

  dailyReportDocumentExportExcel(data) {
    return this.http.postBlob(API_CONSTANT.daily_report_document_export_excel, data);
  }

  periodReportDocument(params?) {
    let url = API_CONSTANT.period_report_document;
    if (params != null) {
      url += '?' + params;
    }
    return this.http.get(url);
  }

  periodReportDocumentExportExcel(data) {
    return this.http.postBlob(API_CONSTANT.period_report_document_export_excel, data);
  }

  cancelDocuments(data) {
    return this.http.post(API_CONSTANT.cancel_documents, data);
  }

}
