import {Component, OnInit} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {HttpParams} from '@angular/common/http';
import * as _ from 'lodash';
import * as moment from 'moment';

import {DailyReport} from './daily-report.model';
import {ApiService} from '../../services/api.service';
import {CALENDAR_LOCALE} from '../../services/constant';

@Component({
  selector: 'app-daily-report',
  templateUrl: './daily-report.component.html',
  styleUrls: ['./daily-report.component.scss']
})
export class DailyReportComponent implements OnInit {
  currentPage = 1;
  itemsPerPage = 40;
  totalItems = 0;
  sortBy = null;
  loading = false;
  tableCol = [];
  tableData = [];
  calendarLocale = CALENDAR_LOCALE.TH;
  startDate: Date = null;
  endDate: Date = null;
  selectedCompany = null;
  companyList: any;
  minDate: Date;
  maxDate: Date;
  modal: NgbModalRef;
  cancelLoadingAllZip = true;
  loadingAllZip = false;
  allZipCurrent = 0;
  allZipCount: number;
  loadingAllZipProgress = 0;
  loadingAllZipMessage = '';
  tableArray: DailyReport[] = [];

  constructor(private translate: TranslateService,
              private apiService: ApiService,
              private modalService: NgbModal) {
  }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.loadTexts();
    });
    this.loadTexts();
  }

  loadTexts() {
    this.tableCol = [];
    if (this.translate.currentLang === 'en') {
      this.calendarLocale = CALENDAR_LOCALE.EN;
    } else {
      this.calendarLocale = CALENDAR_LOCALE.TH;
    }
    this.translate.get('COMMON.ALL').subscribe((res: string) => {

      this.tableCol.push(
        {field: 'company', header: this.translate.instant('FILTER.COMPANY-NAME')},
        {field: 'import_date', header: this.translate.instant('TABLE.IMPORT-DATE')},
        {field: 'document_count', header: this.translate.instant('TABLE.NUMBER-DOCUMENTS')}
      );
      this.tableCol = _.uniqBy(this.tableCol, 'field');

      this.companyList = [
        {label: this.translate.instant('COMMON.ALL'), value: null},
        ...DailyReport.FILTER_COMPANY_CODE
      ];
      this.selectedCompany = null;

    });
  }

  loadInput() {
    this.loading = true;
    this.tableData = [];

    const startDateToStr = this.startDate === null ? this.startDate : moment(this.startDate).format('YYYY-MM-DD');
    const endDateToStr = this.endDate === null ? this.endDate : moment(this.endDate).format('YYYY-MM-DD');
    const params = new HttpParams()
      .set('page', _.toString(this.currentPage))
      .set('page_size', _.toString(this.itemsPerPage))
      .set('company', _.toString(this.selectedCompany))
      .set('date_start', _.toString(startDateToStr))
      .set('date_end', _.toString(endDateToStr))
      .set('ordering', _.toString(this.sortBy));

    this.apiService.dailyReportDocument(params).subscribe(
      res => {
        this.totalItems = res.count;
        for (const item of res.results) {
          this.tableData.push(new DailyReport(item));
        }
        this.loading = false;
      },
      error => error(status)
    );

  }

  pageChanged() {
    this.loadInput();
  }

  searchButtonClicked() {
    this.currentPage = 1;
    this.sortBy = null;
    this.loadInput();
  }

  sort(event) {
    const field = event.field;
    this.sortBy = event.order === 1 ? field : '-' + field;
  }

  clearSearch() {
    this.selectedCompany = null;
    this.startDate = null;
    this.endDate = null;
    this.totalItems = 0;

    this.searchButtonClicked();
  }

  get dateRange() {
    if (!this.startDate && !this.endDate) {
      return null;
    }

    const start = this.startDate ? moment(this.startDate).format('DD/MM/YYYY') : '';
    const end = this.endDate ? moment(this.endDate).format('DD/MM/YYYY') : '';
    return start + ' - ' + end;
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  get yearRange() {
    const thisYear = (new Date()).getFullYear();
    return (thisYear - 40) + ':' + thisYear;
  }

  setMinDate() {
    this.minDate = this.startDate;
  }

  setMaxDate() {
    this.maxDate = this.endDate;
  }

  closeDownloadAll() {
    this.modal.close();
    this.cancelLoadingAllZip = true;
    this.loadingAllZip = false;
    this.allZipCurrent = 0;
    this.loadingAllZipProgress = 0;
  }

  EnabledDownloadSelected() {
    return this.tableData.filter(row => row.selected === true).length > 0;
  }

  downloadSelected(content) {
    this.tableArray = [];

    this.tableArray = this.tableData.filter((item: DailyReport) => item.selected === true);

    if (!_.isEmpty(this.tableArray)) {
      this.modal = this.modalService.open(content, {backdrop: 'static', keyboard: false});
      this.allZipCount = this.tableArray.length;
      this.cancelLoadingAllZip = false;
      this.download(0, this.tableArray);

    }
  }

  download(index: number, tableArray: DailyReport[]) {
    this.loadingAllZip = true;

    if (index >= tableArray.length) {
      this.loadingAllZip = false;
      // this.loadingAllZipMessage = `Download เสร็จสมบูรณ์ (ทั้งหมด ${tableArray.length} รายการ)`;
      this.loadingAllZipMessage = `${this.translate.instant('ZIP-FILE.DOWNLOAD-SUCCESS')} (${tableArray.length} ${this.translate.instant('COMMON.ITEMS')})`;
      return;
    }

    if (this.cancelLoadingAllZip) {
      return;
    }

    const data = {
      company: tableArray[index].companyCode,
      import_date: tableArray[index].getImportDateToStr()
    };
    this.apiService.dailyReportDocumentExportExcel(data).subscribe(
      obj => {
        if (this.cancelLoadingAllZip) {
          return;
        }
        this.apiService.createDownloadFile(obj, tableArray[index].exportFileName);
        this.allZipCurrent++;
        this.loadingAllZipProgress = (this.allZipCurrent / this.allZipCount) * 100;
        // data.xmlLoading = false;
        setTimeout(() => {
          this.download(index + 1, tableArray);
        }, 1000);
      },
      error => {
        this.loadingAllZip = false;
        // this.loadingAllZipMessage = 'เกิดข้อผิดพลาด โปรดลองอีกครั้ง';
        this.loadingAllZipMessage = this.translate.instant('ERROR.TRY-AGAIN');
      }
    );

  }

  selectDeselectAll(checked) {
    if (checked) {
      this.selectAll();
      return;
    }
    this.deselectAll();
  }

  selectAll() {
    for (const row of this.tableData) {
      row.selected = true;
    }
  }

  deselectAll() {
    for (const row of this.tableData) {
      row.selected = false;
    }
  }

}
