<div class="header">
  <h3>{{'PROFILE.LIST.TITLE' | translate}}</h3>
</div>

<div class="box-filter position-relative z-2">
  <app-filter-header [loading]="loading" (search)="search()" (clear)="clearSearch()"></app-filter-header>

  <div class="row">
    <div class="col">
      <div class="form-label-group">
        <input class="form-control" type="text" id="username" [(ngModel)]="searchUsername" placeholder="{{'PROFILE.LIST.FILTER.USERNAME' | translate}}">
        <label for="username">{{'PROFILE.LIST.FILTER.USERNAME' | translate}}</label>
      </div>
    </div>
<!--    <div class="col">-->
<!--      <div class="dropdown-label-group">-->
<!--        <p-dropdown-->
<!--          class="dv-dropdown"-->
<!--          selectId="group"-->
<!--          [options]="groups"-->
<!--          [(ngModel)]="searchGroup"-->
<!--          [autoDisplayFirst]="false"-->
<!--          placeholder="{{'PROFILE.LIST.FILTER.LICENSE' | translate}}"-->
<!--        >-->
<!--        </p-dropdown>-->
<!--        <label for="group">{{'PROFILE.LIST.FILTER.LICENSE' | translate}}</label>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</div>

<div class="box-parent position-relative z-1">
  <app-custom-pagination
    [(currentPage)]="currentPage"
    [(itemsPerPage)]="itemsPerPage"
    [totalItems]="totalItems"
    (pageChange)="pageChanged()"
    (optionChange)="optionChanged()"
  >
    <button type="button" class="btn btn-dv btn-wide" (click)="openModal(null, -1)">{{'TEXT.ADD_ROLE' | translate}}</button>
  </app-custom-pagination>

  <div class="scrollable m-t-30">
    <table class="table text-center row-hover" *ngIf="!loading">
      <thead>
      <tr>
        <th scope="col">{{'COMMON.TABLE.ORDER' | translate}}</th>
        <th scope="col">{{'PROFILE.LIST.FILTER.USERNAME' | translate}}</th>
        <th scope="col">{{'TEXT.ROLE' | translate}}</th>
<!--        <th scope="col">{{'FILTER.COMPANY' | translate}}</th>-->
        <th scope="col">{{'TAX-B2P.STATUS' | translate}}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let res of list; let index = index" (click)="openModal(res, index)">
        <td>{{((currentPage - 1) * itemsPerPage) + index + 1}}</td>
        <td>{{res.username}}</td>
        <td>
          <div *ngIf="res.is_superuser">admin</div>
          <div *ngIf="!res.is_superuser">user</div>
        </td>
<!--        <td>-->
<!--          <span *ngFor="let name of res.company_group; let i = index">{{name.name}}-->
<!--          <span *ngIf="res.company_group && !(res.company_group.length - i == 1)">,</span>-->
<!--          </span>-->
<!--        </td>-->
        <td>
          <div *ngIf="res.is_active">{{'PROFILE.LIST.TABLE.STATUS.ENABLED' | translate}}</div>
          <div *ngIf="!res.is_active">{{'PROFILE.LIST.TABLE.STATUS.SUSPENDED' | translate}}</div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="row m-t-20" *ngIf="loading">
    <div class="col-12 text-center">
      <i class="fa fa-spinner fa-2x fa-spin m-r-5"></i>
    </div>
  </div>
</div>

<profile-list-detail #detailModal ></profile-list-detail>

