import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import * as _ from 'lodash';
import {AuthenticationService} from '../services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  username: string;
  password: string;
  isLoading = false;
  errorMessage: string = null;
  loginMode = true;
  forgotPasswordMode = false;
  email: string;
  passwordResetText = 'Please enter your email address.';
  resetPasswordSuccessMode = false;

  constructor(
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private tokenService: AuthenticationService,
    private router: Router
  ) {
  }

  ngOnInit() {
    localStorage.clear();
    this.activatedRoute.params.subscribe(params => {
      this.errorMessage = params.msg || null;
    });
  }

  forgotPassword() {
    this.isLoading = true;
    this.tokenService
      .forgotPassword({
        email: this.email,
      })
      .subscribe(
        (user: any) => {
          this.passwordResetText = 'โปรดตรวจสอบที่อยู่อีเมลที่เชื่อมต่อกับบัญชีของคุณรีเซ็ตรหัสผ่าน';
          this.isLoading = false;
          this.forgotPasswordMode = false;
          this.resetPasswordSuccessMode = true;
        },
        error => {
          this.isLoading = false;
          this.errorMessage = 'เกิดข้อผิดพลาด โปรดกรอกอีเมล';
          // this.errorHandler(error.status);
        }
      );
  }

  gotoLogin() {
    this.loginMode = true;
    this.forgotPasswordMode = false;
    this.resetPasswordSuccessMode = false;
    this.passwordResetText = 'Please enter your email address.';
    this.email = '';
  }

  login() {
    this.errorMessage = null;

    if (_.isNil(this.username) || _.isNil(this.password)) {
      this.errorMessage = 'โปรดกรอกข้อมูลให้ครบถ้วน';
      return;
    }

    this.authService.login(this.username, this.password).subscribe(
      user => {
        const token = _.get(user, 'token');

        localStorage.clear();
        localStorage.setItem('currentUser', token);
        this.authService.updateUserInfo(user);

        if (user['group'] == 'token') {
          this.router.navigate(['/search-customer']);
        }
        // tslint:disable-next-line:one-line
        else {
          this.router.navigate(['']);
        }
      },
      error => {
        if (error != null && error.status === 400) {
          this.errorMessage = 'ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง';
        } else {
          this.errorMessage = 'เกิดข้อผิดพลาด โปรดลองอีกครั้ง';
        }
      }
    );
  }

}
