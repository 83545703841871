import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CalendarModule, CheckboxModule, DropdownModule, TableModule} from 'primeng';

import {CoreModule} from '../../core/core.module';
import {DailyReportComponent} from './daily-report.component';
import {DailyReportRoutingModule} from './daily-report-routing.module';


@NgModule({
  declarations: [DailyReportComponent],
  imports: [
    CommonModule,
    DailyReportRoutingModule,
    TranslateModule,
    CoreModule,
    TableModule,
    CheckboxModule,
    DropdownModule,
    FormsModule,
    NgbModule,
    CalendarModule
  ]
})
export class DailyReportModule {
}
