import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ITEMS_PER_PAGE_OPTIONS, PAGINATION} from '../../services/constant';

@Component({
  selector: 'app-custom-pagination',
  templateUrl: './custom-pagination.component.html',
  styleUrls: ['./custom-pagination.component.scss']
})
export class CustomPaginationComponent implements OnInit {
  @Output() currentPageChange = new EventEmitter();
  @Output() itemsPerPageChange = new EventEmitter();

  @Input() totalItems: any = 0;
  @Input() maxSize: any = PAGINATION.MAX_SIZE;
  @Input() hideItemsPerPage: boolean = false;
  @Input() rightSide: boolean = false;

  @Output() pageChange = new EventEmitter();
  @Output() optionChange = new EventEmitter();

  itemsPerPageOptions = ITEMS_PER_PAGE_OPTIONS;
  currentPageValue = 1;
  itemsPerPageValue = PAGINATION.PAGE_SIZE;

  constructor() {
  }

  ngOnInit() {
  }

  @Input()
  get currentPage() {
    return this.currentPageValue;
  }

  set currentPage(value) {
    this.currentPageValue = value;
    this.currentPageChange.emit(value);
  }

  @Input()
  get itemsPerPage() {
    return this.itemsPerPageValue;
  }

  set itemsPerPage(value) {
    this.itemsPerPageValue = value;
    this.itemsPerPageChange.emit(value);
  }
}
