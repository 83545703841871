<div class="header">
  <h3>{{'IMPORT-DATA.TITLE' | translate}}</h3>
</div>

<div class="box-filter position-relative z-2">
  <app-filter-header [loading]="loading" (search)="searchButtonClicked()" (clear)="clearSearch()"></app-filter-header>

  <div class="row row-eq-height">

    <div class="col-md-4">
      <div class="dropdown-label-group">
        <p-dropdown
          class="dv-dropdown"
          selectId="documentType"
          [options]="statusData"
          [(ngModel)]="selectedStatus"
          [autoDisplayFirst]="false"
          id='statusData'
          placeholder="{{'IMPORT-DATA.STATUS-TYPE' | translate}}"
        >
        </p-dropdown>
        <label for="statusData">{{'IMPORT-DATA.STATUS-TYPE' | translate}}</label>
      </div>
    </div>
    <div class="col-md-4">
      <div class="dropdown" #dd="ngbDropdown" ngbDropdown placement="bottom-right" autoClose="outside">
        <div class="form-label-group">
          <input #ddi class="form-control" type="text" id="calendarDropdown" [value]="dateRange"
                 ngbDropdownToggle placeholder="{{'FILTER.DATE-OF-ISSUE' | translate}}" readonly>
          <label for="calendarDropdown">{{'FILTER.DATE-OF-ISSUE' | translate}}</label>
          <div class="calendar-trigger" (click)="$event.stopPropagation(); sentdate(); dd.toggle()">
            <span class="far fa-calendar"></span>
          </div>
        </div>
        <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="calendarDropdown">
          <div class="row">
            <div class="col" style="padding-left: 30px">{{'FILTER.CALENDAR.SINCE' | translate}}</div>
            <div class="col" style="padding-left: 30px">{{'FILTER.CALENDAR.UNTIL' | translate}}</div>
          </div>
          <div (click)="stopPropagation($event)">
            <p-calendar [(ngModel)]="startDate" [defaultDate]="startDate" dateFormat="dd/mm/yy" [maxDate]="maxDate"
                        readonlyInput="true" [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true"
                        [yearRange]="yearRange" (onSelect)="setMinDate()" [inline]="true"
                        [locale]="calendarLocale"></p-calendar>
            <p-calendar [(ngModel)]="endDate" [defaultDate]="startDate" dateFormat="dd/mm/yy" [minDate]="minDate"
                        readonlyInput="true" [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true"
                        [yearRange]="yearRange" (onSelect)="setMaxDate(); dd.close()" [inline]="true"
                        [locale]="calendarLocale"></p-calendar>
          </div>
        </div>
      </div>
    </div>

  </div>

  <!--  <div class="row" *ngIf="errorMsg">-->
  <!--    <div class="col text-center text-red">-->
  <!--      {{ errorMsg }}-->
  <!--    </div>-->
  <!--  </div>-->
</div>
<div class="box-parent position-relative z-1">
  <app-custom-pagination
    [(currentPage)]="currentPage"
    [(itemsPerPage)]="itemsPerPage"
    [totalItems]="totalItems"
    [maxSize]="5"
    (pageChange)="pageChanged()"
    (optionChange)="searchButtonClicked()"
    *ngIf="loading === false && totalItems !== 0"
  >
  </app-custom-pagination>

  <div class="row m-t-20" *ngIf="totalItems === 0 && loading === false">
    <div class="col-12 text-center">
      <h5>{{'COMMON.DATA-NOT-FOUND' | translate}}</h5>
    </div>
  </div>

  <div class="m-t-20 text-center scrollable">
    <p-table #tt tableStyleClass="table" *ngIf="totalItems !== 0" [loading]="false"
             [columns]="tableCol" [value]="tableData" [customSort]="true" (onSort)="sort($event)" [autoLayout]="true">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th scope="col">{{'NORMAL.QUE' | translate}}</th>
          <!--          <th *ngFor="let col of columns" [pSortableColumn]="col.field" (click)="pageChanged()">-->
          <!--            {{col.header}}-->
          <!--          </th>-->
          <th scope="col">{{'NORMAL.D' | translate}}</th>
          <th scope="col">{{'NORMAL.FILE-NAME' | translate}}</th>
          <th scope="col">{{'NORMAL.MESSAGE' | translate}}</th>
          <th scope="col" [ngStyle]="{'width':'90px'}">{{'NORMAL.NOTE' | translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data let-index="rowIndex">
        <tr>
          <td>{{index + 1 + ((currentPage - 1) * itemsPerPage)}}</td>
          <td>{{data.created_at | date:"dd/MM/yyyy HH:mm:ss" }}</td>
          <td>{{data.filename}}</td>
          <td>{{data.result}}</td>
          <!--          <td>{{data.remark}}</td>-->

          <td style="text-align: center" *ngIf="data.remark">
            <i (click)="resolveError($event, popup,data)"
               class="fa fa-eye"
               style="cursor:pointer;"
               aria-hidden="true"
               [title]="data.remark"></i>
          </td>
          <td style="text-align: center" *ngIf="!data.remark">
            <span>-</span>
          </td>
        </tr>
      </ng-template>

    </p-table>

  </div>

  <div class="row m-t-20" *ngIf="loading">
    <div class="col-12 text-center">
      <i class="fa fa-spinner fa-2x fa-spin m-r-5"></i>
    </div>
  </div>
</div>
<!-------------------------modal popup------------------------------------------->
<ng-template #popup let-modal>
  <div class="modal-header">
    <h3>{{'NORMAL.NOTE' | translate}}: </h3>
    <button type="button" class="close" aria-label="Close" (click)="revertBack()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body bg-model">
    <div class="row bg-model">
      <div class="col-12 text-center bg-model">
        <p>{{modalData}}</p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="text-center">
      <button type="button" class="btn btn-dv btn-danger btn-wide redius-border"
              (click)="revertBack()">{{'COMMON.CLOSE' | translate}}
      </button>
    </div>
  </div>
  <!--  </div>-->
</ng-template>

