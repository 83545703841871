var url = window['location']['href'];
// var matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
var matches = url.match(/^http(s?)?\:\/\/([^\/?#]+)/);
// var domain = matches && matches[0];
var domain = matches[0];

export const environment = {
  production: false,
  apiURL: domain
};
