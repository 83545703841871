<div class="row">
  <div class="col-8">

  </div>
<div style="width: 40%" class="box-parent col-4">
   <div class="dropdown-label-group">
      <p-dropdown [options]="dashboards"
                  [(ngModel)]="selectDashboard"
                  class="dv-dropdown"
                  selectId="year"
                  id="dashboards"
                  placeholder="{{'DOC-DASHBOARD.DASHBOARDLIST' | translate}}"
                  (onChange)="choosedashboard()"

      >
      </p-dropdown>
   <label for="dashboards">{{'DOC-DASHBOARD.DASHBOARDLIST' | translate}}</label>

   </div>

     </div>
</div>

<div [ngSwitch]="keepdashboard" >
   <div *ngSwitchCase="null" class="mt-2">
     <app-dashboard-document></app-dashboard-document>
   </div>


        <div *ngSwitchCase="2" >
          <div style="margin-top: -30px">
            <app-dashborad-cloud-usage></app-dashborad-cloud-usage>

          </div>
        </div>
</div>
