<div>
  <h1></h1>
</div>
 <div >
   <div >
          <div class="header">
            <div class="row" >
              <div class="col-md">
                <h3>{{'DASHBOARD.TITLE' | translate}}</h3>
              </div>
              <div >
                  <p-dropdown
                    class="dv-dropdown"
                    selectId="year"
                    [options]="yearList"
                    [(ngModel)]="selectedYear"
                    [autoDisplayFirst]="false"
                    placeholder="ปี"
                    (onChange)="loadMonthlyData()"
                  >
                  </p-dropdown>
              </div>
            </div>
          </div>
      <div class="col-md">
                <h4>Siam Furukawa Co.,Ltd.</h4>
      </div>
      <div class="box-parent scrollable mb-4" style="height: calc(100vh - 2.25rem - 150px);">
        <canvas id="signing-counter-bar-chart-1000">{{ charts_signing_counter_bar_stacked_1 }}</canvas>
      </div>

     <div class="col-md">
                <h4>Siam Furukawa Trading Co.,Ltd.</h4>
      </div>
     <div class="box-parent scrollable" style="height: calc(100vh - 2.25rem - 150px);">
        <canvas id="signing-counter-bar-chart-2000">{{ charts_signing_counter_bar_stacked_2 }}</canvas>
      </div>
   </div>

</div>
