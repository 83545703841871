import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardDocumentComponent } from './dashboard-document/dashboard-document.component';
import {CalendarModule, DropdownModule} from 'primeng';
import {FormsModule} from '@angular/forms';
import {DashboardRoutingModule} from "./dashboard-routing.module";
import { DashboardForItComponent } from './dashboard-for-it/dashboard-for-it.component';
import { DashboardListComponent } from './dashboard-list/dashboard-list.component';
import {NgbDropdownModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import { DashboradCloudUsageComponent } from './dashborad-cloud-usage/dashborad-cloud-usage.component';




@NgModule({
  declarations: [
    DashboardDocumentComponent,
    DashboardForItComponent,
    DashboardListComponent,
    DashboradCloudUsageComponent,
  ],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    DashboardRoutingModule,
    CalendarModule,
    NgbDropdownModule,
    TranslateModule
  ]
})
export class DashboardModule { }
