<div class="row align-items-center mb-3">
  <div class="col">
    <h5>{{'FILTER.TITLE' | translate}}</h5>
  </div>
  <div class="col-auto">
    <div>
      <button class="btn btn-dv btn-clean btn-wide mr-2" (click)="clear.emit()">
        <i class="fa fa-times m-r-5"></i>
        {{'FILTER.CLEAR' | translate}}
      </button>
      <button class="btn btn-dv" style="width: 100px;" (click)="search.emit()">
        <i *ngIf="loading === true" class="fa fa-spinner fa-spin m-r-5"></i>
        <i *ngIf="loading === false" class="fa fa-search m-r-5"></i>
        {{'FILTER.TITLE' | translate}}
      </button>
    </div>
  </div>
</div>
