<div class="row align-items-center" style="justify-content: flex-end;" ngClass="{'m-t-10': !rightSide}">
  <div class="col-auto page-size" *ngIf="!hideItemsPerPage">
    {{'PAGINATION.SHOW' | translate}}
    <p-dropdown
      class="dropdown-pagination"
      [options]="itemsPerPageOptions"
      [(ngModel)]="itemsPerPage"
      [autoDisplayFirst]="false"
      (onChange)="optionChange.emit()"
    >
    </p-dropdown>
    {{'PAGINATION.ITEMS-PAGES' | translate}}
  </div>
  <div class="col-auto v-divider" *ngIf="!hideItemsPerPage"></div>
  <div class="dv-pagination" style="flex-grow: 1">
    <ngb-pagination [collectionSize]="totalItems" [(page)]="currentPage" [maxSize]="maxSize"
                    [pageSize]="itemsPerPage" aria-label="Pagination" (pageChange)="pageChange.emit()"></ngb-pagination>
  </div>
  <div class="col-auto" *ngIf="!rightSide">
    <ng-content></ng-content>
  </div>
</div>
