import {HttpParams} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import {CALENDAR_LOCALE} from '../../services/constant';
import {ApiService} from '../../services/api.service';
import {SelectItem} from 'primeng';
import {Document} from './document.model';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  currentPage = 1;
  itemsPerPage = 40;
  totalItems = 0;
  sortBy = null;
  loading = false;
  calendarLocale = CALENDAR_LOCALE.TH;
  tableData = [];
  docTypeList: any;
  selectedDocType: SelectItem;
  companyList: any;
  selectedCompany: SelectItem;
  tableCol = [
    {field: 'status', header: 'สถานะ'},
    {field: 'company_code', header: 'บริษัท'},
    {field: 'doc_number', header: 'เลขที่เอกสาร'},
    {field: 'year', header: 'ปี'},
    {field: 'created_date', header: 'วันที่เอกสาร'},
    {field: 'created_time', header: 'เวลาสร้างเอกสาร'},
    {field: 'doc_type', header: 'ประเภทเอกสาร'},
  ];
  searchInputText: string;
  startDate: Date = null;
  endDate: Date = null;
  minDate: Date;
  maxDate: Date;
  loadingAllZip = false;
  cancelLoadingAllZip = false;
  allZipCurrent = 0;
  allZipCount: number;
  loadingAllZipProgress = 0;
  loadingAllZipMessage = '';
  modal: NgbModalRef;
  tableArray: Document[] = [];

  constructor(private translate: TranslateService,
              private apiService: ApiService,
              private modalService: NgbModal) {
  }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.loadTexts();
    });
    this.loadTexts();
  }

  loadTexts() {
    this.tableCol = [];
    if (this.translate.currentLang === 'en') {
      this.calendarLocale = CALENDAR_LOCALE.EN;
    } else {
      this.calendarLocale = CALENDAR_LOCALE.TH;
    }
    this.translate.get('COMMON.ALL').subscribe((res: string) => {

      this.tableCol.push(
        {field: 'status', header: this.translate.instant('NORMAL.STATUS')},
        {field: 'company_code', header: this.translate.instant('FILTER.COMPANY-NAME')},
        {field: 'doc_number', header: this.translate.instant('FILTER.DOCUMENT-NUMBER')},
        {field: 'year', header: this.translate.instant('FILTER.YEAR')},
        {field: 'created_date', header: this.translate.instant('FILTER.DATE')},
        {field: 'created_time', header: this.translate.instant('FILTER.CREATE-TIME')},
        {field: 'doc_type', header: this.translate.instant('FILTER.DOCUMENT-TYPE')},
      );
      this.tableCol = _.uniqBy(this.tableCol, 'field');

      this.docTypeList = [
        {label: this.translate.instant('COMMON.ALL'), value: null},
        ...Document.FILTER_DOC_TYPE
      ];
      this.selectedDocType = null;

      this.companyList = [
        {label: this.translate.instant('COMMON.ALL'), value: null},
        ...Document.FILTER_COMPANY_CODE
      ];
      this.selectedCompany = null;

    });
  }

  loadInput() {
    this.loading = true;
    this.tableData = [];

    const startDateToStr = this.startDate === null ? this.startDate : moment(this.startDate).format('YYYY-MM-DD');
    const endDateToStr = this.endDate === null ? this.endDate : moment(this.endDate).format('YYYY-MM-DD');
    const params = new HttpParams()
      .set('page', _.toString(this.currentPage))
      .set('page_size', _.toString(this.itemsPerPage))
      .set('company_code', _.toString(this.selectedCompany))
      .set('doc_type', _.toString(this.selectedDocType))
      .set('search', _.toString(this.searchInputText))
      .set('date_start', _.toString(startDateToStr))
      .set('date_end', _.toString(endDateToStr))
      .set('ordering', _.toString(this.sortBy));

    this.apiService.searchDocuments(params).subscribe(
      res => {
        this.totalItems = res.count;
        for (const item of res.results) {
          this.tableData.push(new Document(item));
        }
        this.loading = false;
      },
      error => error(status)
    );
  }

  pageChanged() {
    this.loadInput();
  }

  searchButtonClicked() {
    this.currentPage = 1;
    this.sortBy = null;
    this.loadInput();
  }

  sort(event) {
    let field: string;
    if (event.field === 'created_date') {
      field = 'created_time';
    } else if (event.field === 'status') {
      field = 'cancel';
    } else {
      field = event.field;
    }
    this.sortBy = event.order === 1 ? field : '-' + field;
  }

  selectDeselectAll(checked) {
    if (checked) {
      this.selectAll();
      return;
    }
    this.deselectAll();
  }

  selectAll() {
    for (const row of this.tableData) {
      row.selected = true;
    }
  }

  deselectAll() {
    for (const row of this.tableData) {
      row.selected = false;
    }
  }

  filterBranchList() {
  }

  clearSearch() {
    this.selectedCompany = null;
    this.selectedDocType = null;
    this.searchInputText = null;
    this.startDate = null;
    this.endDate = null;
    this.totalItems = 0;

    this.searchButtonClicked();
  }

  get dateRange() {
    if (!this.startDate && !this.endDate) {
      return null;
    }

    const start = this.startDate ? moment(this.startDate).format('DD/MM/YYYY') : '';
    const end = this.endDate ? moment(this.endDate).format('DD/MM/YYYY') : '';
    return start + ' - ' + end;
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  setMinDate() {
    this.minDate = this.startDate;
  }

  setMaxDate() {
    this.maxDate = this.endDate;
  }

  get yearRange() {
    const thisYear = (new Date()).getFullYear();
    return (thisYear - 40) + ':' + thisYear;
  }

  onOpenFile(url: string) {
    window.open(url);
  }

  closeDownloadAll() {
    this.modal.close();
    this.cancelLoadingAllZip = true;
    this.loadingAllZip = false;
    this.allZipCurrent = 0;
    this.loadingAllZipProgress = 0;
  }

  EnabledDownloadSelected() {
    return this.tableData.filter(row => row.selected === true).length > 0;
  }

  cancelSelected() {
    const selectedDocs = this.tableData.filter((item: Document) => item.selected === true && !item.isCancel());
    let selectedDocsId = [];
    selectedDocs.forEach((doc: Document) => {
      selectedDocsId.push(doc.id);
    });

    if (selectedDocsId.length === 0) {
      return;
    }

    const data = {
      cancel_docs: selectedDocsId
    };
    const confirmFlag = confirm(this.translate.instant('NORMAL.CANCEL-DOCUMENT-CONFIRM'));
    this.loading = true;
    this.tableData = [];
    if (confirmFlag) {
      this.apiService.cancelDocuments(data).subscribe(res => {
        this.searchButtonClicked();
        this.loading = false;
      });
    }
  }

  downloadSelected(content) {
    this.tableArray = [];

    this.tableArray = this.tableData.filter((item: Document) => item.selected === true);

    if (!_.isEmpty(this.tableArray)) {
      this.modal = this.modalService.open(content, {backdrop: 'static', keyboard: false});
      this.allZipCount = this.tableArray.length;
      this.cancelLoadingAllZip = false;
      this.download(0, this.tableArray);

    }
  }

  download(index: number, tableArray: Document[]) {
    this.loadingAllZip = true;

    if (index >= tableArray.length) {
      this.loadingAllZip = false;
      // this.loadingAllZipMessage = `Download เสร็จสมบูรณ์ (ทั้งหมด ${tableArray.length} รายการ)`;
      this.loadingAllZipMessage = `${this.translate.instant('ZIP-FILE.DOWNLOAD-SUCCESS')} (${tableArray.length} ${this.translate.instant('COMMON.ITEMS')})`;
      return;
    }

    if (this.cancelLoadingAllZip) {
      return;
    }

    this.apiService.downloadDocument(tableArray[index].getLinkToFile()).subscribe(
      obj => {
        if (this.cancelLoadingAllZip) {
          return;
        }

        this.apiService.createDownloadFile(obj, tableArray[index].rawFilename);
        this.allZipCurrent++;
        this.loadingAllZipProgress = (this.allZipCurrent / this.allZipCount) * 100;
        // data.xmlLoading = false;
        setTimeout(() => {
          this.download(index + 1, tableArray);
        }, 1000);
      },
      error => {
        this.loadingAllZip = false;
        // this.loadingAllZipMessage = 'เกิดข้อผิดพลาด โปรดลองอีกครั้ง';
        this.loadingAllZipMessage = this.translate.instant('ERROR.TRY-AGAIN');
      }
    );

  }

}
