<div class="header">
  <h3>{{'MENU.PERIOD-REPORT' | translate}}</h3>
</div>

<div class="box-filter position-relative z-2">
  <app-filter-header [loading]="loading" (search)="searchButtonClicked()" (clear)="clearSearch()"></app-filter-header>

  <div class="row row-eq-height">
    <div class="col-md-6">
      <div class="dropdown-label-group">
        <p-dropdown
          class="dv-dropdown"
          selectId="selectedCompany"
          [options]="companyList"
          [(ngModel)]="selectedCompany"
          [autoDisplayFirst]="false"
          placeholder="{{'FILTER.D' | translate}}"
          id="companyFilter">
        </p-dropdown>
        <label for="companyFilter">{{'FILTER.COMPANY-NAME' | translate}}</label>
      </div>
    </div>

    <div class="col-md-6">
      <div class="dropdown" #dd="ngbDropdown" ngbDropdown placement="bottom-right" autoClose="outside">
        <div class="form-label-group">
          <input #ddi class="form-control" type="text" id="calendarDropdown" [value]="dateRange"
                 ngbDropdownToggle placeholder="{{'FILTER.DATE-OF-ISSUE' | translate}}" readonly>
          <label for="calendarDropdown">{{'FILTER.DATE-OF-ISSUE' | translate}}</label>
          <div class="calendar-trigger" (click)="$event.stopPropagation(); dd.toggle()">
            <span class="far fa-calendar"></span>
          </div>
        </div>
        <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="calendarDropdown">
          <div class="row">
            <div class="col" style="padding-left: 30px">{{'FILTER.CALENDAR.SINCE' | translate}}</div>
            <div class="col" style="padding-left: 30px">{{'FILTER.CALENDAR.UNTIL' | translate}}</div>
          </div>
          <div (click)="stopPropagation($event)">
            <p-calendar [(ngModel)]="startDate" [defaultDate]="startDate" dateFormat="dd/mm/yy" [maxDate]="maxDate"
                        readonlyInput="true" [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true"
                        [yearRange]="yearRange" (onSelect)="setMinDate()" [inline]="true"
                        [locale]="calendarLocale"></p-calendar>
            <p-calendar [(ngModel)]="endDate" [defaultDate]="startDate" dateFormat="dd/mm/yy" [minDate]="minDate"
                        readonlyInput="true" [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true"
                        [yearRange]="yearRange" (onSelect)="setMaxDate(); dd.close()" [inline]="true"
                        [locale]="calendarLocale"></p-calendar>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--  <div class="row" *ngIf="errorMsg">-->
  <!--    <div class="col text-center text-red">-->
  <!--      {{ errorMsg }}-->
  <!--    </div>-->
  <!--  </div>-->
</div>

<div class="box-parent position-relative z-1">
  <app-custom-pagination
    [(currentPage)]="currentPage"
    [(itemsPerPage)]="itemsPerPage"
    [totalItems]="totalItems"
    [maxSize]="5"
    (pageChange)="pageChanged()"
    (optionChange)="searchButtonClicked()"
    *ngIf="loading === false && totalItems !== 0">

    <!--      <button type="button" class="btn btn-dv btn-clean btn-wide" (click)="selectAll()">เลือกทั้งหมด</button>-->
    <!--      <button type="button" class="btn btn-dv btn-wide" [disabled]="!EnabledDownloadSelected()" (click)="DownloadSelected(content2, 'xml')">-->
    <!--        {{'COMMON.DOWNLOAD' | translate}} XML-->
    <!--      </button>-->
    <button type="button" class="btn btn-dv btn-wide" [disabled]="!EnabledDownloadSelected()"
            (click)="downloadSelected(content2)">
      {{'NORMAL.EXPORT-EXCEL' | translate}}
    </button>
  </app-custom-pagination>

  <div class="row m-t-20" *ngIf="totalItems === 0 && loading === false">
    <div class="col-12 text-center">
      <h5>{{'COMMON.DATA-NOT-FOUND' | translate}}</h5>
    </div>
  </div>

  <div class="m-t-20 text-center ">
    <p-table #tt tableStyleClass="table" *ngIf="totalItems !== 0" [loading]="false"
             [columns]="tableCol" [value]="tableData" [customSort]="true" (onSort)="sort($event)" [autoLayout]="true">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th scope="col">{{'COMMON.TABLE.ORDER' | translate}}</th>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field" (click)="pageChanged()">
            {{col.header}}
<!--            <p-sortIcon [field]="col.field"></p-sortIcon>-->
          </th>
          <th scope="col">
            <p-checkbox class="dv-checkbox" binary="true" (onChange)="selectDeselectAll($event)"></p-checkbox>
          </th>
          <!--          <th scope="col">{{'COMMON.CANCEL' | translate}}</th>-->
          <!--        </tr>-->
      </ng-template>
      <ng-template pTemplate="body" let-data let-index="rowIndex">
        <tr>
          <td>{{index + 1 + ((currentPage - 1) * itemsPerPage)}}</td>
          <td>{{data.getLabelCompanyCode()}}</td>
          <td>{{data.documentCount}}</td>
          <td style="text-align: center">
            <p-checkbox class="dv-checkbox" binary="true"
                        [(ngModel)]="data.selected"></p-checkbox>
          </td>
          <!--          <td>-->
          <!--            <button *ngIf="data.status == 'active' " (click)="cancelInvoice(data,index)" class="btn btn-dv btn-wide">{{'TEXT.CANCEL'| translate}}</button>-->
          <!--            <i *ngIf="data.status == 'cancelled' " class="fa fa-exclamation" aria-hidden="true"></i>-->
          <!--          </td>-->
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="row m-t-20" *ngIf="loading">
    <div class="col-12 text-center">
      <i class="fa fa-spinner fa-2x fa-spin m-r-5"></i>
    </div>
  </div>
</div>

<ng-template #content2 let-modal>
  <div class="modal-header">
    <!-- <h4 class="modal-title" id="modal-basic-title">Alert</h4> -->
    <button type="button" class="close" aria-label="Close" [disabled]="loadingAllZip" (click)="closeDownloadAll()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row" style="margin-bottom: 10px">
      <div *ngIf="loadingAllZip" class="col-12 text-center">
        <i class="fa fa-spinner fa-2x fa-spin" style="margin-left:5px"></i>
        <br/>
        {{allZipCurrent}} / {{allZipCount}}
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <ngb-progressbar *ngIf="loadingAllZip === true" type="info" [value]="loadingAllZipProgress"
                         height="20px"></ngb-progressbar>
        <span *ngIf="loadingAllZip === false" style="color: #4d5f7a">{{loadingAllZipMessage}}</span>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-dv btn-danger btn-wide"
            (click)="closeDownloadAll()">{{'COMMON.CLOSE' | translate}}</button>
  </div>
</ng-template>
