import { NgModule } from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {PasswordResetComponent} from "./password-reset.component";
// import { CommonModule } from '@angular/common';

export const passwordResetRouters: Routes = [
  {
    path: 'password-reset',
    children: [
      {
        path: ':token',
        component: PasswordResetComponent
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(passwordResetRouters)],
  exports: [RouterModule]
})
export class PasswordResetRoutingModule { }
