import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileListComponent } from './profile-list/profile-list.component';
import {FormsModule} from "@angular/forms";
import {DropdownModule} from "primeng";
import {ProfileRoutingModule} from "./profile-routing.module";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {ModalModule} from "ngx-bootstrap";
import {CoreModule} from "../../core/core.module";
import {TranslateModule} from "@ngx-translate/core";
import { ProfileListDetailComponent } from './profile-list-detail/profile-list-detail.component';
import { ProfilePasswordComponent } from './profile-password/profile-password.component';



@NgModule({
  declarations: [
    ProfileListComponent,
    ProfileListDetailComponent,
    ProfilePasswordComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    DropdownModule,
    ProfileRoutingModule,
    NgbModule,
    ModalModule.forRoot(),
    CoreModule,
    TranslateModule
  ]
})
export class ProfileModule { }
