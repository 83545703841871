import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { AuthenticationService } from '../../services/authentication.service';
import { TemplateService } from '../../services/template.service';
import { HttpParams } from '@angular/common/http';
import * as _ from 'lodash';
import * as moment from 'moment';
import {CALENDAR_LOCALE} from '../../services/constant';
import {SelectItem} from 'primeng/api';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-search-customer',
  templateUrl: './search-customer.component.html',
  styleUrls: ['./search-customer.component.scss'],
  providers: [TemplateService]
})
export class SearchCustomerComponent implements OnInit {
  itemsPerPage = 40;

  selectedCompany: SelectItem[];
  company: any;
  selectedHomeproGroup: SelectItem[];
  homeproGroup: any;
  allBranch = true;
  allDocType = true;
  branch: any;
  selectedBranch: SelectItem[];
  allBranchList: any = null;
  branchSuggest: any;
  branchList: any = null;
  docTypeList: any = null;
  typeSuggest: any;
  documentType: any;
  allDocumentType = [];
  allBranchSelected = [];
  rdDocumentType: any;
  selectedRdDocumentType: any;

  selectedType: any;
  inputText: any;
  tableData: any;
  startDate: Date = null;
  endDate: Date = null;
  minDate: Date;
  maxDate: Date;
  totalItems: number = 0;
  selectedValues = [];
  currentPage = 1;
  checked = false;
  url: any;
  date: Date;
  docTypes: any;
  loading = false;
  loadingZip = false;
  errorMsg = '';
  defaultLabel :any;

  calendarLocale = CALENDAR_LOCALE.TH;
  loading_all_zip = false;
  cancel_loading_all_zip = false;
  loading_all_zip_message = '';
  loading_all_zip_progress: number = 0;
  tableArray = [];

  modal: NgbModalRef;
  allZipCount: number;
  allZipCurrent = 0;

  sortBy: string;
  sortMode: string;

  tableCol = [
    { field: 'invoice_number', header: 'เลขที่เอกสาร' },
    { field: 'header_issue_date', header: 'วันที่ออกเอกสาร' },
    { field: 'branch', header: 'ชื่อสาขา' },
    { field: 'rd_doc_type', header: 'ประเภทเอกสาร' },
    // { field: 'buyer_name', header: 'ชื่อผู้ซื้อ' },
    { field: 'total_items_total_payment', header: 'ยอดขาย' }
  ];

  constructor(private apiService: ApiService,
              private authService: AuthenticationService,
              private modalService: NgbModal,
              private template: TemplateService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.loadTexts();
    });
    this.loadTexts();
    // this.loadInput();
    // this.loadDropdown();

    this.rdDocumentType = this.template.getDropdown();
  }

  loadTexts() {
    this.defaultLabel = this.translate.instant('COMMON.ALL');
    this.tableCol = [];
    if (this.translate.currentLang === 'en') {
      this.calendarLocale = CALENDAR_LOCALE.EN;
    } else {
      this.calendarLocale = CALENDAR_LOCALE.TH;
    }
    this.translate.get('COMMON.ALL').subscribe((res: string) => {
      const documentTypeData = this.template.getDropdown();
      // documentTypeData[0] = {label: this.translate.instant('COMMON.ALL'), value: null};
      this.rdDocumentType = documentTypeData;
      this.loadInput();
      this.tableCol.push(
        {field: 'invoice_number', header: this.translate.instant('FILTER.DOCUMENT-NUMBER')},
        {field: 'header_issue_date', header: this.translate.instant('FILTER.DATE-OF-ISSUE')},
        {field: 'branch', header: this.translate.instant('TABLE.BRANCH-NAME')},
        {field: 'rd_doc_type', header: this.translate.instant('FILTER.DOCUMENT-TYPE')},
        // { field: 'buyer_name', header: 'ชื่อผู้ซื้อ' },
        {field: 'total_items_total_payment', header: this.translate.instant('TABLE.SALES')}
      );
      this.tableCol = _.uniqBy(this.tableCol, 'field');
    });
  }

  loadInput() {
    this.selectedCompany = null;
    this.company = null;
    this.company = [{
      label: this.translate.instant('COMMON.ALL'),
      value: null
    }];

    this.apiService.getCompany().subscribe(
      (result) => {
        _.forEach(result, (obj) => {
          this.company.push({
            label: obj.name,
            value: obj.id
          })
        });
        this.company = _.uniqBy(this.company, 'label');
      },
      error => error(status)
    )

    this.homeproGroup = [{
      label: this.translate.instant('COMMON.ALL'),
      value: null
    }];

    this.apiService.getCompanyGroup().subscribe(
      (result) => {
        _.forEach(result, (obj) => {
          this.homeproGroup.push({
            label: obj.name,
            value: obj.id
          })
        });
      }
    );

    this.apiService.getBranch().subscribe(
      (result) => {
        this.allBranchList = result;
        this.filterBranchList();
      }
    );

    this.startDate = new Date();
    this.startDate.setDate(this.startDate.getDate() - 60);
    this.endDate = new Date();
  }

  get yearRange() {
    const thisYear = (new Date()).getFullYear();
    return (thisYear - 40) + ':' + thisYear;
  }

  get dateRange() {
    if (!this.startDate && !this.endDate) {
      return null;
    }

    const start = this.startDate ? moment(this.startDate).format('DD/MM/YYYY') : '';
    const end = this.endDate ? moment(this.endDate).format('DD/MM/YYYY') : '';
    return start + ' - ' + end;
  }

  filterBranchList() {
    this.selectedBranch = null;

    this.branchList = [{
      label: this.translate.instant('COMMON.ALL'),
      value: null
    }];

    _.forEach(this.allBranchList, (obj) => {
      if(this.selectedCompany == null || obj.corporate == this.selectedCompany)
        this.branchList.push({
          label: obj.full_name,
          value: obj.id
        })
    });
  }

  searchBranch(event) {
    if (this.branchList == null) {
      this.apiService.getBranch().subscribe(
        (data) => {
          this.branchList = data;
          this.branchSuggest = this.filterData(event.query, 'full_name', this.branchList);
        }
      )
    } else {
      this.branchSuggest = this.filterData(event.query, 'full_name', this.branchList);
    }
  }

  searchDocumentType(event) {
    if (this.docTypeList == null) {
      this.apiService.getDocumentType().subscribe(
        (result) => {
          this.docTypeList = result;
          this.typeSuggest = this.filterData(event.query, 'type', this.docTypeList)
        }
      )
    } else {
      this.typeSuggest = this.filterData(event.query, 'type', this.docTypeList)
    }
  }

  selectAllBranch() {
      this.branch = null;
  }

  selectDocType() {
      this.documentType = null;
  }

  clearBranch() {
    this.allBranchSelected = [];
  }

  clearDocType() {
    this.allDocumentType = [];
  }

  filterData(query, field, dataList) {
    query = query.toLowerCase().trim();

    let filtered: any[] = [];
    for (let i = 0; i < dataList.length; i++) {
      let data = dataList[i];
      if (data[field].toLowerCase().indexOf(query) >= 0) {
        filtered.push(data);
      }
    }
    return filtered;
  }

  loadDropdown() {
    this.docTypes = this.template.getDropdown();
  }

  pageChanged() {
    const params = new HttpParams().set('page', _.toString(this.currentPage));
    this.loadData(params);
  }

  setMinDate() {
    this.minDate = this.startDate;
  }

  setMaxDate() {
    this.maxDate = this.endDate;
  }

  clearSearch() {
    this.selectedType = null;
    this.inputText = null;
    this.selectedCompany = null;
    this.selectedHomeproGroup = null;
    this.branch = null;
    this.documentType = null;
    this.allDocType = true;
    this.allBranch = true;
    this.selectedBranch = null;
    this.selectedRdDocumentType=null;
    this.sortBy = null;
    this.sortMode = null;
    this.startDate = new Date();
    this.startDate.setDate(this.startDate.getDate() - 60);
    this.endDate = new Date();

    this.searchButtonClicked();
  }

  searchButtonClicked() {
    this.currentPage = 1;
    this.sortBy = null;
    this.sortMode = null;
    this.loadData();
  }

  loadData(params?) {
    this.checkCondition();

    if(this.errorMsg.length > 0)
      return;

    this.allBranchSelected = [];
    this.allDocumentType = [];
    _.forEach(this.branch, (data) => {
      // this.allBranchSelected = [];
      this.allBranchSelected.push(_.toNumber(data.id))
    });
    _.forEach(this.documentType, (data) => {
      // this.allDocumentType = [];
      this.allDocumentType.push(_.toNumber(data.id))
    });

    const data = {
      rd_doc_type: this.selectedRdDocumentType,
      search_box: this.inputText,
      header_issue_date_start: this.startDate==null ? this.startDate : moment(this.startDate).format('YYYY-MM-DD'),
      header_issue_date_end: this.endDate==null ? this.endDate : moment(this.endDate).format('YYYY-MM-DD'),
      group_id: this.selectedHomeproGroup,
      company_id: this.selectedCompany,
      document_type_id: (_.isNil(this.documentType)) ? this.documentType : _.uniq(this.allDocumentType),
      //branch_id: (_.isNil(this.branch)) ? this.branch : _.uniq(this.allBranchSelected),
      branch_id: this.selectedBranch ? [this.selectedBranch] : null,
      items_per_page: this.itemsPerPage
    };

    if (this.sortBy && this.sortMode) {
      data['sort'] = this.sortBy;
      data['sort_type'] = this.sortMode;
    }

    this.loading = true;

    this.apiService.postSearch(data, params).subscribe(
      (res: any) => {
        this.tableData = res.results;
        this.totalItems = res.count;

        this.selectedValues = [];
        for(let i=0;i<this.tableData.length;i++)
          this.selectedValues.push(false);

        this.loading = false;
      }
    );
  }

  DownloadSelected(content, doc_type) {
    this.tableArray = [];

    for(let i=0;i < this.selectedValues.length;i++) {
      if(!this.selectedValues[i]|| this.tableData[i].status == "cancelled" || this.tableData[i].status == "ccNoRef")
        continue;

      this.tableArray.push(this.tableData[i]);
    }

    if (!_.isEmpty(this.tableArray)) {
      this.modal = this.modalService.open(content, {backdrop: 'static', keyboard: false});
      this.allZipCount = this.tableArray.length;
      this.cancel_loading_all_zip = false;

      if(doc_type == 'xml')
        this.download(0, this.tableArray, 'xml_file');
      else
        this.download(0, this.tableArray, 'pdf_file');
    }
  }

  EnabledDownloadSelected() {
    return this.selectedValues.filter(select => select == true).length > 0
  }

  download(index, tableArray, url_field_name){
    this.loading_all_zip = true;

    if (index >= tableArray.length) {
      this.loading_all_zip = false;
      this.loading_all_zip_message = "Download เสร็จสมบูรณ์ (ทั้งหมด " + tableArray.length + " รายการ)";
      return;
    }

    if (this.cancel_loading_all_zip) {
      return;
    }

    let download_url = tableArray[index][url_field_name];

    //skip if download url is empty.
    if (download_url == null || download_url.length == 0) {
      this.download(index + 1, tableArray, url_field_name);
      return;
    }

    this.apiService.downloadFileFromMediaPath(download_url).subscribe(
      (obj) => {
        if (this.cancel_loading_all_zip) {
          return;
        }

        // this.loading_all_zip = false;
        let filename = this.getFileName(tableArray[index]);

        if(url_field_name == 'xml_file')
          filename = filename + '.xml';
        else
          filename = filename + '.pdf';

        this.apiService.createDownloadFile(obj, filename);
        this.allZipCurrent++;
        this.loading_all_zip_progress = (this.allZipCurrent / this.allZipCount) * 100;
        // data.xmlLoading = false;
        setTimeout(() => {
          this.download(index + 1, tableArray, url_field_name);
        }, 1000);
      },
      error => {
        this.loading_all_zip = false;
        this.loading_all_zip_message = "เกิดข้อผิดพลาด โปรดลองอีกครั้ง";
        // data.xmlLoading = false;
      }
    )
  }

  closeDownloadAll() {
    this.modal.close();
    this.cancel_loading_all_zip = true;
    this.loading_all_zip = false;
    this.allZipCurrent = 0;
    this.loading_all_zip_progress = 0;
  }

  getFileName(data) {
    let file_name = '' + data.header_invoice_number + '_' + data.rd_document_type + '_' + data.header_issue_dt;
    file_name = file_name.replace(/([^a-z0-9]+)/gi, '_');
    return file_name
  }

  selectDeselectAll(checked) {
    if (checked) {
      this.selectAll();
      return;
    }
    this.deselectAll();
  }

  selectAll() {
    for(let i=0;i < this.tableData.length;i++) {
      this.selectedValues[i] = true;
    }
  }

  deselectAll() {
    for(let i=0;i < this.tableData.length;i++) {
      this.selectedValues[i] = false;
    }
  }

  sort(event) {
    this.sortBy = event.field;
    if (event.order === 1) {
      this.sortMode = 'asc';
    } else {
      this.sortMode = 'desc';
    }
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  checkCondition() {
    this.errorMsg = '';

    let errorMsgList = [];
    if(_.isEmpty(this.branch) && this.allBranch === false) {
       errorMsgList.push(this.translate.instant('FILTER.BRANCH'));
    }

    if (_.isEmpty(this.documentType) && this.allDocType === false) {
      errorMsgList.push('Doc Type');
    }

    if (errorMsgList.length > 0) {
        this.errorMsg = this.translate.instant('COMMON.SPECIFY') + ' ' + errorMsgList.join(', ') + ' ' + this.translate.instant('COMMON.CORRECT');
      return;
    }

    let dayDiff = Math.abs(moment(this.endDate).diff(moment(this.startDate), 'days'));
    if ( dayDiff > 60 && this.authService.isAccountant() ){
      this.errorMsg = this.translate.instant('FILTER.ERROR.DIFF');
    }
  }
  cancelInvoice(tableData, index){
    const data = {
      corporate_branch_name: tableData.corporate_branch__name,
      header_invoice_number: tableData.header_invoice_number,
      header_issue_dt: tableData.header_issue_dt,
      rd_document_type: tableData.rd_document_type
    };
    this.apiService.cancelNoRef(data).subscribe(
      res => {
        this.tableData[index].status = 'cancelled';
      },
      error => {
        console.log(error);
      }
    )

  }
}
