import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MainRoutingModule} from "./main-routing.module";
import {RouterModule} from "@angular/router";
import {FormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {MainComponent} from "./main.component";
import {NavbarComponent} from '../core/navbar/navbar.component';
import {LoginComponent} from '../login/login.component';
import {AuthGuard} from '../auth-guard.service';
import {ApiService} from '../services/api.service';
import {AuthenticationService} from '../services/authentication.service';
import {HttpClientService} from '../services/http-client.service';
import {FeaturesModule} from '../features/features.module';
import {ValidateOtpComponent} from '../validate-otp/validate-otp.component';
import {PasswordResetModule} from "../password-reset/password-reset.module";

@NgModule({
  declarations: [
    MainComponent,
    NavbarComponent,
    LoginComponent,
    ValidateOtpComponent
  ],
  imports: [
    CommonModule,
    MainRoutingModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    TranslateModule,
    FeaturesModule,
    PasswordResetModule

  ],
  providers: [
    AuthGuard,
    ApiService,
    AuthenticationService,
    HttpClientService
  ]
})
export class MainModule { }
