import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CalendarModule, CheckboxModule, DropdownModule, TableModule} from 'primeng';

import {CoreModule} from '../../core/core.module';
import {PeriodReportComponent} from './period-report.component';
import {PeriodReportRoutingModule} from './period-report-routing.module';


@NgModule({
  declarations: [PeriodReportComponent],
  imports: [
    CommonModule,
    PeriodReportRoutingModule,
    TranslateModule,
    CoreModule,
    TableModule,
    CheckboxModule,
    DropdownModule,
    FormsModule,
    NgbModule,
    CalendarModule
  ]
})
export class PeriodReportModule {
}
