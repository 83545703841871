import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ImportDataComponent} from './import-data.component';
import {ImportDataRoutingModule} from './import-data-routing.module';
import {
  AutoCompleteModule,
  CalendarModule,
  CheckboxModule,
  DropdownModule,
  TableModule,
  TooltipModule
} from 'primeng';
import {CoreModule} from '../../core/core.module';
import {TranslateModule} from '@ngx-translate/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from '@angular/forms';


@NgModule({
  declarations: [ImportDataComponent],
  imports: [
    CommonModule,
    ImportDataRoutingModule,
    CalendarModule,
    CheckboxModule,
    AutoCompleteModule,
    CoreModule,
    TableModule,
    TranslateModule,
    NgbModule,
    FormsModule,
    DropdownModule,
    TooltipModule
  ]
})
export class ImportDataModule {
}
