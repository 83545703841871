import {environment} from '../../environments/environment';
import * as moment from 'moment';
import Base = moment.unitOfTime.Base;

const BASE_URL = environment.apiURL;
export const API_URL = {
  password_reset: BASE_URL + '/api/password_reset/',
  confirm_password: BASE_URL + '/api/password_reset/confirm/',
  reset_password_validate: BASE_URL + '/api/password_reset/validate_token/'
};

export const API_CONSTANT = {
  'BASE_URL': BASE_URL,
  MEDIA_PATH_URL: BASE_URL + '/media/',
  request_otp: BASE_URL + '/api/auth/request-otp/',
  validate_otp: BASE_URL + '/api/auth/validate-otp/',
  login: BASE_URL + '/api-token-auth/',
  upload_csv: BASE_URL + '/api/tax_imports/',
  sign_docs: BASE_URL + '/api/sign_doc/',
  digital_signature: BASE_URL + '/api/certificate/',
  tax_data_upload_result: BASE_URL + '/api/tax_data_upload_result/',
  background_jobs: BASE_URL + '/api/background_jobs/',
  summary_csv: BASE_URL + '/api/summary_csv/',
  zip_file: BASE_URL + '/api/zip_file/',
  sales_tax_report: BASE_URL + '/api/sales_tax_report/',
  zip_file_two_megabyte: BASE_URL + '/api/zip_file_two_megabyte/',
  search_files: BASE_URL + '/api/search_files/',
  sign_counter: BASE_URL + '/api/sign_counter/',
  mark_download: BASE_URL + '/api/mark_download/',
  dashboard_monthly: BASE_URL + '/api/dashboard_monthly/',
  dashboard_sms_monthly: BASE_URL + '/api/dashboard_sms_monthly/',
  dashboard_sign_counter_minute: BASE_URL + '/api/dashboard_sign_counter_minute/',
  dashboard_length: BASE_URL + '/api/dashboard_length/',
  dashboard_activity: BASE_URL + '/api/dashboard_activity/',
  set_password: BASE_URL + '/api/set_password/',
  groups: BASE_URL + '/api/groups/',
  me: BASE_URL + '/api/me/',
  me_set_password: BASE_URL + '/api/me/set_password/',
  corporates: BASE_URL + '/api/corporates/',
  group_corporates: BASE_URL + '/api/group-corporates/',
  branches: BASE_URL + '/api/corporate-branches/',
  document_type: BASE_URL + '/api/documents_type/',
  sales_tax_view: BASE_URL + '/api/sales_tax_views/',
  bulk_sales_tax_view: BASE_URL + '/api/bulk_sales_tax_views/',
  export_missing_excel: BASE_URL + '/api/export_excel_missing_docs/',
  export_bulk_missing_excel: BASE_URL + '/api/export_bulk_excel_missing_docs/',
  approve_sale_tax: BASE_URL + '/api/approved_sales_tax/',
  export_all_docs: BASE_URL + '/api/export_excel_all_docs/',
  export_bulk_all_docs: BASE_URL + '/api/export_bulk_excel_all_docs/',
  export_monthly_excel_report: BASE_URL + '/api/export_excel_monthly_report/',
  sales_zip_tax: BASE_URL + '/api/sales_tax_zip_views/',
  approve_upload_sale: BASE_URL + '/api/approved_uploaded_sales_tax_zip/',
  export_zip_file: BASE_URL + '/api/zip_file_two_megabyte/',
  buyer: BASE_URL + '/api/buyer/',
  b2p_document_summary: BASE_URL + '/api/b2p_document_summary/',
  b2p_document_summary_detail: BASE_URL + '/api/b2p_document_summary_detail/',
  b2p_document_summary_detail_excel: BASE_URL + '/api/b2p_document_summary_detail_excel/',
  export_billing_monthly_excel_report: BASE_URL + '/api/export_sign_counter_excel_monthly_report/',
  email_report: BASE_URL + '/api/email_report/',
  cancel_no_ref: BASE_URL + '/api/cancel_no_ref/',
  errors: BASE_URL + '/api/errors/',
  import_data: BASE_URL + '/api/import-data',
  search_documents: BASE_URL + '/api/document/',
  cancel_documents: BASE_URL + '/api/document/cancel/',
  daily_report_document: BASE_URL + '/api/document/daily_report/',
  period_report_document: BASE_URL + '/api/document/period_report/',
  daily_report_document_export_excel: BASE_URL + '/api/document/daily_report/export_excel/',
  period_report_document_export_excel: BASE_URL + '/api/document/period_report/export_excel/',
  dashboard_monthly_count: BASE_URL + '/api/monthly_document_count/',
  dashboard_monthly_storage: BASE_URL + '/api/monthly_document_storage/',
  users: BASE_URL + '/api/users/users_manage/',
  import_document: BASE_URL + '/api/import_document/',
  password_reset: BASE_URL + '/api/ password_reset/'
};
