import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ImportDataComponent} from './import-data.component';

export const importDataRoutes: Routes = [
  {
    path: 'import-data',
    component: ImportDataComponent
  }
]

@NgModule({
  imports: [RouterModule.forChild(importDataRoutes)],
  exports: [RouterModule]
})
export class ImportDataRoutingModule { }

