<ng-template #modalTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
  </div>
  <div class="modal-body box-parent">
    <div class="row">
      <div class="col">
        <div class="form-label-group">
          <input type="text" id="username" class="form-control" [(ngModel)]="user.username" placeholder="{{'PROFILE.LIST.FILTER.USERNAME' | translate}}">
          <label for="username">{{'PROFILE.LIST.FILTER.USERNAME' | translate}}</label>
        </div>
        <div class="form-label-group">
          <input type="text" id="email" class="form-control" [(ngModel)]="user.email" placeholder="{{'NORMAL.EMAIL' | translate}}">
          <label for="email">{{'NORMAL.EMAIL' | translate}}</label>
        </div>
        <div class="form-label-group">
          <input type="text" id="firstname" class="form-control" [(ngModel)]="user.first_name" placeholder="{{'PROFILE.LIST.FILTER.FIRST-NAME' | translate}}">
          <label for="firstname">{{'PROFILE.LIST.FILTER.FIRST-NAME' | translate}}</label>
        </div>
        <div class="form-label-group">
          <input type="text" id="lastname" class="form-control" [(ngModel)]="user.last_name" placeholder="{{'PROFILE.LIST.FILTER.LAST-NAME' | translate}}">
          <label for="lastname">{{'PROFILE.LIST.FILTER.LAST-NAME' | translate}}</label>
        </div>
        <div class="form-label-group">
          <input type="password" id="password" class="form-control" [(ngModel)]="user.password" placeholder="{{'PROFILE.LIST.FILTER.PASSWORD' | translate}}">
          <label for="password">{{'PROFILE.LIST.FILTER.PASSWORD' | translate}}</label>
        </div>
        <div class="dropdown-label-group" *ngIf="!user.is_superuser">
          <p-dropdown
            class="dv-dropdown"
            selectId="group"
            [options]="statusChoice"
            [(ngModel)]="user.is_active"
            [autoDisplayFirst]="false"
            placeholder="{{'NORMAL.STATUS' | translate}}"
            id="userStatus"
          >
          </p-dropdown>
          <label for="userStatus">{{'NORMAL.STATUS' | translate}}</label>
        </div>

        <div *ngIf="!user.is_superuser">
          <div style="display: flex; align-items: center;">
            <button class="col-auto btn btn-dv btn-danger btn-wide" (click)="disableUser()">{{'TEXT.DELETE-USER' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer m-b-0">
      <div class="col">
        <div class="row m-b-5">
          <div class="col text-center text-red">{{ errorMessage ? errorMessage : '&nbsp;' }}</div>
        </div>
        <div class="row justify-content-center">
          <div class="col">
            <button class="btn btn-dv btn-clean btn-wide m-r-20" (click)="modalRef.hide()">{{'COMMON.CANCEL' | translate}}</button>
          </div>
           <button class="col-auto btn btn-dv btn-wide pull-right" (click)="submit()">{{ createMode ? ('PROFILE.PASSWORD.CONFIRM' | translate) : ('PROFILE.LIST.MODAL.MODIFY' | translate) }}</button>
        </div>
      </div>
  </div>
</ng-template>
