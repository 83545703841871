import {Component, OnInit} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {ApiService} from '../../../services/api.service';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../services/authentication.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Chart} from 'chart.js';
import * as _ from 'lodash';
import {datepickerAnimation} from "ngx-bootstrap/datepicker/datepicker-animations";

@Component({
  selector: 'app-dashboard-document',
  templateUrl: './dashboard-document.component.html',
  styleUrls: ['./dashboard-document.component.scss']
})
export class DashboardDocumentComponent implements OnInit {
  // month amount to show in graph
  monthAmount = 6;
  // activity amount to show in activity list
  activityAmount = 2;
  monthList = [];
  monthListDropdown = [
    {label: 'มกราคม', value: 1},
    {label: 'กุมภาพันธ์', value: 2},
    {label: 'มีนาคม', value: 3},
    {label: 'เมษายน', value: 4},
    {label: 'พฤษภาคม', value: 5},
    {label: 'มิถุนายน', value: 6},
    {label: 'กรกฎาคม', value: 7},
    {label: 'สิงหาคม', value: 8},
    {label: 'กันยายน', value: 9},
    {label: 'ตุลาคม', value: 10},
    {label: 'พฤศจิกายน', value: 11},
    {label: 'ธันวาคม', value: 12}
  ];

  dashboards: SelectItem[];
  selectDashboard: string;
  Dashboard: any[];
  yearList: any[] = [];
  selectedCompany: SelectItem[];
  company: any;
  selectedHomeproGroup: SelectItem[];
  homeproGroup: any;
  selectedMonth: any;
  selectedYear: any;
  activityList: any;
  selectedDate: Date = new Date();

  charts_bar_stacked: any = null;
  charts_signing_counter_bar_stacked_1: any = null;
  charts_signing_counter_bar_stacked_2: any = null;
  charts_signing_counter_line: any = null;
  chartLabels = [];
  monthly_loading = false;
  daily_loading = false;
  bar_stacked_title = "";
  charts_signing_counter_bar_stacked_title = "";
  charts_transaction_count_title = "";
  isShow = false;
  test = [];
  keepdashboard: any;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private authService: AuthenticationService,
    private translate: TranslateService) {
    this.dashboards = [
      {label: 'Documents Dashboard', value: 1},
      {label: 'SMS Dashboard', value: 2},
      {label: 'Emails Dashboard', value: 3},
      {label: 'Document Request Dashboard', value: 4},
    ];

    if (this.authService.isAccountant()) {
      this.router.navigate(['/taxReport']);
    }
  }

  ngOnInit() {
    this.loadGraph();
    this.loadActivity();
    this.loadMonthList();
    this.translate.get('COMMON.ALL').subscribe(() => {
      this.populateDropdown();
    });
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.loadMonthList();
      this.loadMonthlyData()
    });
  }

  loadMonthList() {
    this.monthList = [];
    this.monthList.push(this.translate.instant('MONTH.JANUARY'), this.translate.instant('MONTH.FEBRUARY'), this.translate.instant('MONTH.MARCH'), this.translate.instant('MONTH.APRIL'), this.translate.instant('MONTH.MAY'), this.translate.instant('MONTH.JUNE'), this.translate.instant('MONTH.JULY'), this.translate.instant('MONTH.AUGUST'), this.translate.instant('MONTH.SEPTEMBER'), this.translate.instant('MONTH.OCTOBER'), this.translate.instant('MONTH.NOVEMBER'), this.translate.instant('MONTH.DECEMBER'));
  }

  populateDropdown() {
    this.company = [{
      label: 'ทั้งหมด',
      value: null
    }];
    //
    // this.apiService.getCompany().subscribe(
    //   (result) => {
    //     _.forEach(result, (obj) => {
    //       this.company.push({
    //         label: obj.name + ' (' + obj.short_name + ')',
    //         value: obj.id
    //       })
    //     });
    //   }
    // );
    //
    // this.homeproGroup = [{
    //   label: this.translate.instant('COMMON.ALL'),
    //   value: null
    // }];
    //
    // this.apiService.getCompanyGroup().subscribe(
    //   (result) => {
    //     _.forEach(result, (obj) => {
    //       this.homeproGroup.push({
    //         label: obj.name + ' (' + obj.short_name + ')',
    //         value: obj.id
    //       })
    //     });
    //   }
    // );

    var currentDate = new Date();
    // populate year list back within 10 years
    var thisYear = currentDate.getFullYear();
    for (let i = 0; i <= 10; i++) {
      this.yearList.push({label: (thisYear - i).toString(), value: thisYear - i});
    }

    // set current month and year
    this.selectedMonth = currentDate.getMonth() + 1;
    this.selectedYear = thisYear;
    this.loadMonthlyData()
  }

  loadGraph() {
  }

  // load data from current month
  loadMonthlyData() {
    let data = {};

    if (this.selectedYear) {
      data['year'] = this.selectedYear;
    }

    this.monthly_loading = true;
    this.apiService.getMonthleCount(data).subscribe(
      (obj: any) => {
        this.monthly_loading = false;
        this.create_signing_counter_bar_stacked_1(obj.document_counter_1000, 1000);
        this.create_signing_counter_bar_stacked_2(obj.document_counter_2000, 2000);


        Chart.plugins.register({
          beforeDraw: function (chart) {
            if (chart.config.options.elements.center) {
              chart.clear();
              //Get ctx from string
              var ctx = chart.chart.ctx;

              //Get options from the center object in options
              var centerConfig = chart.config.options.elements.center;
              var fontStyle = centerConfig.fontStyle || 'Arial';
              var txt = centerConfig.text;
              var color = centerConfig.color || '#000';
              var sidePadding = centerConfig.sidePadding || 20;
              var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
              //Start with a base font of 30px
              ctx.font = "30px " + fontStyle;
              var stringWidth = ctx.measureText(txt).width;
              var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

              // Find out how much the font can grow in width.
              var widthRatio = elementWidth / stringWidth;
              var newFontSize = Math.floor(30 * widthRatio);
              var elementHeight = (chart.innerRadius * 2);

              // Pick a new font size so it will not be larger than the height of label.
              var fontSizeToUse = Math.min(newFontSize, elementHeight);

              //Set font settings to draw it correctly.
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
              var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 1.2);
              ctx.font = fontSizeToUse + "px " + fontStyle;
              ctx.fillStyle = color;

              //Draw text in center
              ctx.fillText(txt, centerX, centerY);
            }
          },

          afterDraw: function (chart) {
            if (chart.data.datasets.length === 0) {
              // No data is present
              var ctx = chart.chart.ctx;
              var width = chart.chart.width;
              var height = chart.chart.height;
              chart.clear();

              ctx.save();
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              ctx.font = "14pt Tahoma";
              ctx.fillStyle = '#000';
              ctx.fillText('ไม่ข้อมูลแสดง', width / 2, height / 2);
              ctx.restore();
            }
          }
        });
      });
  }

  create_signing_counter_bar_stacked_1(data, company_code) {
    let INV = data.INV;
    let CND = data.CND;
    let DND = data.DND;
    let WHT = data.WHT;
    let color = Chart.helpers.color;

    this.charts_signing_counter_bar_stacked_title = 'จำนวนครั้งการ Sign รายเดือน';
    var barChartData_1 = {
      labels: this.monthList,
      datasets: [{
        label: this.translate.instant('TEXT.INV'),
        backgroundColor: color("rgb(46,155,97)").alpha(0.5).rgbString(),
        borderColor: "rgb(46,155,97)",
        data: INV
      }, {
        label: this.translate.instant('TEXT.CND'),
        backgroundColor: color("rgb(75, 192, 192)").alpha(0.5).rgbString(),
        borderColor: "rgb(75, 192, 192)",
        data: CND
      }, {
        label: this.translate.instant('TEXT.DND'),
        backgroundColor: color("rgb(255, 159, 64)").alpha(0.5).rgbString(),
        borderColor: "rgb(255, 159, 64)",
        data: DND
      }, {
        label: this.translate.instant('TEXT.WHT'),
        backgroundColor: color("rgb(255, 99, 132)").alpha(0.5).rgbString(),
        borderColor: "rgb(255, 99, 132)",
        data: WHT
      }]
    };

    if (this.charts_signing_counter_bar_stacked_1 == null && company_code === 1000) {
      this.charts_signing_counter_bar_stacked_1 = new Chart('signing-counter-bar-chart-1000', {
        type: 'bar',
        data: barChartData_1,
        options: {
          tooltips: {
            titleFontSize: 17,
            bodyFontSize: 17,
            mode: 'index',
            intersect: false,
            callbacks: this.getformatLabelFunction(this.translate.instant('COMMON.TIMES'), true)
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true,
            }],
            yAxes: [{
              stacked: true,
              ticks: {
                beginAtZero: true,
                callback: function (value) {
                  if (value % 1 === 0) {
                    return value;
                  }
                }
              }
            }]
          },
          legend: {
            position: 'top',
          }
        }
      });
    } else {
      this.charts_signing_counter_bar_stacked_1.config.options.tooltips.callbacks = this.getformatLabelFunction(this.translate.instant('COMMON.TIMES'), true);
      this.charts_signing_counter_bar_stacked_1.data = barChartData_1;
      this.charts_signing_counter_bar_stacked_1.update();
    }
  }

  create_signing_counter_bar_stacked_2(data, company_code) {
    let INV = data.INV;
    let CND = data.CND;
    let DND = data.DND;
    let WHT = data.WHT;
    let color = Chart.helpers.color;

    this.charts_signing_counter_bar_stacked_title = 'จำนวนครั้งการ Sign รายเดือน';
    var barChartData_2 = {
      labels: this.monthList,
      datasets: [{
        label: this.translate.instant('TEXT.INV'),
        backgroundColor: color("rgb(46,155,97)").alpha(0.5).rgbString(),
        borderColor: "rgb(46,155,97)",
        data: INV
      }, {
        label: this.translate.instant('TEXT.CND'),
        backgroundColor: color("rgb(75, 192, 192)").alpha(0.5).rgbString(),
        borderColor: "rgb(75, 192, 192)",
        data: CND
      }, {
        label: this.translate.instant('TEXT.DND'),
        backgroundColor: color("rgb(255, 159, 64)").alpha(0.5).rgbString(),
        borderColor: "rgb(255, 159, 64)",
        data: DND
      }, {
        label: this.translate.instant('TEXT.WHT'),
        backgroundColor: color("rgb(255, 99, 132)").alpha(0.5).rgbString(),
        borderColor: "rgb(255, 99, 132)",
        data: WHT
      }]
    };

    if (this.charts_signing_counter_bar_stacked_2 == null && company_code === 2000) {
      this.charts_signing_counter_bar_stacked_2 = new Chart('signing-counter-bar-chart-2000', {
        type: 'bar',
        data: barChartData_2,
        options: {
          tooltips: {
            titleFontSize: 17,
            bodyFontSize: 17,
            mode: 'index',
            intersect: false,
            callbacks: this.getformatLabelFunction(this.translate.instant('COMMON.TIMES'), true)
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true,
            }],
            yAxes: [{
              stacked: true,
              ticks: {
                beginAtZero: true,
                callback: function (value) {
                  if (value % 1 === 0) {
                    return value;
                  }
                }
              }
            }]
          },
          legend: {
            position: 'top',
          }
        }
      });
    } else {
      this.charts_signing_counter_bar_stacked_2.config.options.tooltips.callbacks = this.getformatLabelFunction(this.translate.instant('COMMON.TIMES'), true);
      this.charts_signing_counter_bar_stacked_2.data = barChartData_2;
      this.charts_signing_counter_bar_stacked_2.update();
    }
  }

  loadActivity() {
    const data = {
      amount: this.activityAmount
    };
    // this.apiService.getDashboardActivity(data).subscribe(
    //   (obj: any) => {
    //     this.activityList = obj;
    //   },
    //   (error) => {
    //   }
    // )
  }

  formatCurrency(currency) {
    return parseFloat(currency).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  getformatLabelFunction(unitName, showSum) {
    let tooltip = {
      label: function (tooltipItem, data) {
        let dataLabel = data.datasets[tooltipItem.datasetIndex].label;
        if (dataLabel == null)
          dataLabel = data.labels[tooltipItem.index];

        let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        let valueStr = ' : ' + value.toLocaleString();
        dataLabel += valueStr + ' ' + unitName;

        if (showSum)
          window['total'] += value;

        return dataLabel;
      }
    };

    if (showSum) {
      tooltip['afterTitle'] = function () {
        window['total'] = 0;
      };

      tooltip['footer'] = (tooltipItem, data) => {
        const text = [this.translate.instant('CHART.TOTAL') + ' : ' + window['total'].toLocaleString() + ' ' + unitName +
        ((window['total'] >= 2 && unitName === 'Time') ? 's' : '')];
        return tooltipItem.length ? text : null;
      };
    }

    return tooltip
  }


}

