import * as _ from 'lodash';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { DOC_TEMPLATES } from './constant';
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class TemplateService {
  documentTemplates = DOC_TEMPLATES;
  getNametitle = "";

  constructor(private apiService: ApiService,
              private translate : TranslateService) {
  }

  get() {
    return this.documentTemplates;
  }

  getTemplate(type) {
    var template: any;
    _.forEach(this.documentTemplates, (obj) => {
      if (obj.type == type) {
        template = obj;
      }
    })
    return template;
  }

  getName(type) {
    let template = this.getTemplate(type)
    if (template == null)
      return null;
    else
      return template.name;
  }

  getNameEN(type) {
    let template = this.getTemplate(type)
    if (template == null)
      return null;
    else
      return template.nameEN;
  }

  getNameTitle(type){
    if(this.translate.currentLang ==='en'){
      this.getNametitle = this.getNameEN(type)
    }
    if(this.translate.currentLang==='th'){
      this.getNametitle = this.getName(type)
    }
    return this.getNametitle;
  }
  getFullName(type) {
    var template = this.getTemplate(type);
    if (template == null)
      return null;

    return template.name + ' ('  + template.nameEN + ')';
  }

  getDropdown() {
    var type = [{
      label: this.translate.instant('COMMON.ALL'),
      value: null
    }];
    _.forEach(this.documentTemplates, (obj) => {
      type.push({
        label: obj.name + ' (' + obj.nameEN + ')',
        value: obj.type
      });
    });
    return type;
  }
}
