/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./password-reset.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "./password-reset.component";
import * as i5 from "../services/api.service";
import * as i6 from "@angular/router";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../services/authentication.service";
var styles_PasswordResetComponent = [i0.styles];
var RenderType_PasswordResetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PasswordResetComponent, data: {} });
export { RenderType_PasswordResetComponent as RenderType_PasswordResetComponent };
function View_PasswordResetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-exclamation-circle"]], null, null, null, null, null))], null, null); }
export function View_PasswordResetComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 36, "div", [["class", "limiter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 35, "div", [["class", "container-login100"], ["style", "background-image: url('/assets/images/demo1.jpg');"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 34, "div", [["class", "wrap-login100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 33, "form", [["class", "login100-form validate-form"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i2.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(5, 4210688, null, 0, i2.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.NgForm]), i1.ɵdid(7, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "login100-form-logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "img", [["src", "/assets/images/fb-logo.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "login100-form-title p-b-34 p-t-27"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Reset Password "])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 23, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 7, "div", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 6, "div", [["class", "wrap-input100 validate-input"], ["data-validate", "Enter username"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 5, "input", [["class", "input100"], ["id", "password1"], ["name", "password"], ["placeholder", "New password"], ["type", "password"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 17).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 17)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 17)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.password1 = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(19, 671744, null, 0, i2.NgModel, [[2, i2.ControlContainer], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(21, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(22, 0, null, null, 6, "div", [["class", "wrap-input100 validate-input"], ["data-validate", "Enter username"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 5, "input", [["class", "input100"], ["id", "password2"], ["name", "password"], ["placeholder", "Confirm password"], ["type", "password"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 24)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 24).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 24)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 24)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.password2 = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(24, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(26, 671744, null, 0, i2.NgModel, [[2, i2.ControlContainer], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(28, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(29, 0, null, null, 3, "div", [["class", "text-yellow text-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PasswordResetComponent_1)), i1.ɵdid(31, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(32, null, [" ", " "])), (_l()(), i1.ɵeld(33, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 2, "div", [["class", "container-login100-form-btn"]], null, null, null, null, null)), (_l()(), i1.ɵeld(35, 0, null, null, 1, "button", [["class", "login100-form-btn"], ["href", "javascript:void(0)"], ["name", "buttonForTesting"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changePassword() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Change Password "]))], function (_ck, _v) { var _co = _v.component; var currVal_14 = "password"; var currVal_15 = _co.password1; _ck(_v, 19, 0, currVal_14, currVal_15); var currVal_23 = "password"; var currVal_24 = _co.password2; _ck(_v, 26, 0, currVal_23, currVal_24); var currVal_25 = _co.errorMessage; _ck(_v, 31, 0, currVal_25); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 7).ngClassValid; var currVal_5 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = i1.ɵnov(_v, 21).ngClassUntouched; var currVal_8 = i1.ɵnov(_v, 21).ngClassTouched; var currVal_9 = i1.ɵnov(_v, 21).ngClassPristine; var currVal_10 = i1.ɵnov(_v, 21).ngClassDirty; var currVal_11 = i1.ɵnov(_v, 21).ngClassValid; var currVal_12 = i1.ɵnov(_v, 21).ngClassInvalid; var currVal_13 = i1.ɵnov(_v, 21).ngClassPending; _ck(_v, 16, 0, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); var currVal_16 = i1.ɵnov(_v, 28).ngClassUntouched; var currVal_17 = i1.ɵnov(_v, 28).ngClassTouched; var currVal_18 = i1.ɵnov(_v, 28).ngClassPristine; var currVal_19 = i1.ɵnov(_v, 28).ngClassDirty; var currVal_20 = i1.ɵnov(_v, 28).ngClassValid; var currVal_21 = i1.ɵnov(_v, 28).ngClassInvalid; var currVal_22 = i1.ɵnov(_v, 28).ngClassPending; _ck(_v, 23, 0, currVal_16, currVal_17, currVal_18, currVal_19, currVal_20, currVal_21, currVal_22); var currVal_26 = (_co.errorMessage ? _co.errorMessage : "\u00A0"); _ck(_v, 32, 0, currVal_26); }); }
export function View_PasswordResetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-password-reset", [], null, null, null, View_PasswordResetComponent_0, RenderType_PasswordResetComponent)), i1.ɵdid(1, 114688, null, 0, i4.PasswordResetComponent, [i5.ApiService, i6.Router, i7.TranslateService, i8.AuthenticationService, i6.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PasswordResetComponentNgFactory = i1.ɵccf("app-password-reset", i4.PasswordResetComponent, View_PasswordResetComponent_Host_0, {}, {}, []);
export { PasswordResetComponentNgFactory as PasswordResetComponentNgFactory };
